import { flipObject } from 'shared/utils';
import { SplunkReporter } from 'reporting/splunk/SplunkReporter';
const splunkReporter = SplunkReporter.getInstance();
const logger = 'businessLogic/Wallet/helpers';
const bankAccountV2ToCPTypesDictionary = {
  'personal checking': 'PERSONAL_CHECKING',
  'personal savings': 'PERSONAL_SAVINGS',
  'business checking': 'BUSINESS_CHECKING',
  'business savings': 'BUSINESS_SAVINGS',
};
const bankAccountCPToV2TypesDictionary = flipObject(bankAccountV2ToCPTypesDictionary);

const cardV2ToCPTypesDictionary = {
  visa: 'VISA',
  mastercard: 'MC',
  discover: 'DISC',
  amex: 'AMEX',
  diners: 'diners',
};
const cardCPToV2TypesDictionary = flipObject(cardV2ToCPTypesDictionary);

const walletEntryDTO = {
  convertToCPFormat: {
    convertFromRest2: function (userWalletRest2) {
      let userWalletCP = {};

      try {
        const { walletType } = userWalletRest2;
        if (isWalletTypeOfBank(walletType)) {
          userWalletCP = {
            ...userWalletRest2,
            payorFirstName: userWalletRest2.name,
          };
        } else if (isWalletTypeOfCreditCard(walletType)) {
          const { address = { addressComponents: [] }, cardType, number } = userWalletRest2;
          let postalCode;
          try {
            postalCode = address.addressComponents.find(({ name }) => name === 'postalCode').value;
          } catch (e) {
            postalCode = '';
            handleWalletDTOError({
              eventType: 'postalCodeTransformation',
              error: e,
              walletId: userWalletRest2.id,
            });
          }

          userWalletCP = {
            ...userWalletRest2,
            postalCode,
            cardType: cardCPToV2TypesDictionary[cardType],
            cardNumber: number,
            payorFirstName: userWalletRest2.name,
          };
        } else {
          throw new Error('unrecognized wallet type');
        }
        delete userWalletCP.address;
        delete userWalletCP.number;
        delete userWalletCP.name;
      } catch (error) {
        handleWalletDTOError({
          eventType: 'convertFromRest2',
          error,
          walletId: userWalletRest2.id,
        });
        return;
      }

      return userWalletCP;
    },
    convertFromV2: function (userWalletV2) {
      let userWalletCP;

      try {
        const { walletType, walletEntryId, isDefaultWalletPaymentMethod } = userWalletV2;
        userWalletCP = {
          id: walletEntryId,
          default: isDefaultWalletPaymentMethod,
          created: '',
          updated: '',
          status: 'ACTIVE',
        };
        if (isWalletTypeOfBank(walletType)) {
          userWalletCP = {
            ...userWalletCP,
            walletType,
            bankCode: userWalletV2.routingNumber,
            bankName: '',
            accountNumber: userWalletV2.accountNumber,
            accountType: bankAccountV2ToCPTypesDictionary[userWalletV2.accountType],
            payorFirstName: userWalletV2.payorFirstName,
          };
        } else if (isWalletTypeOfCreditCard(walletType)) {
          userWalletCP = {
            ...userWalletCP,
            walletType: 'card',
            cardNumber: userWalletV2.creditCardNumber,
            expMonth: userWalletV2.expirationMonth,
            expYear: userWalletV2.expirationYear,
            cardType: userWalletV2.creditCardType,
            payorFirstName: userWalletV2.nameOnCard,
            postalCode: userWalletV2.postalCode,
          };
        } else {
          throw new Error('unrecognized wallet type');
        }
      } catch (error) {
        handleWalletDTOError({ eventType: 'convertFromV2', error, walletId: userWalletV2.id });
        return;
      }
      return userWalletCP;
    },
  },
  convertFromCPFormat: {
    convertToRest2: function (userWalletCP) {
      let userWalletRest2 = {};
      try {
        const { walletType } = userWalletCP;
        if (isWalletTypeOfBank(walletType)) {
          const { accountType, bankCode, bankName, payorFirstName, walletType, accountNumber } =
            userWalletCP;

          userWalletRest2 = {
            accountType: bankAccountV2ToCPTypesDictionary[accountType] || accountType,
            bankCode,
            bankName,
            name: payorFirstName,
            walletType,
            default: userWalletCP.default,
            accountNumber,
          };
        } else if (isWalletTypeOfCreditCard(walletType)) {
          const { postalCode, cardType, expMonth, expYear, payorFirstName } = userWalletCP;

          userWalletRest2 = {
            address: {
              addressComponents: [{ name: 'postalCode', value: postalCode }],
            },
            cardType: cardV2ToCPTypesDictionary[cardType],
            default: userWalletCP.default,
            expMonth,
            expYear,
            name: payorFirstName,
            walletType,
          };
        } else {
          throw new Error('unrecognized wallet type');
        }
      } catch (error) {
        handleWalletDTOError({
          eventType: 'convertToRest2',
          error,
          walletId: userWalletCP.id,
        });
        return;
      }
      return userWalletRest2;
    },
    convertToV2: function (userWalletCP) {
      let userWalletV2;
      try {
        const { walletType, id } = userWalletCP;
        userWalletV2 = {
          walletEntryId: id,
          isDefaultWalletPaymentMethod: userWalletCP.default,
        };

        if (isWalletTypeOfBank(walletType)) {
          userWalletV2 = {
            ...userWalletV2,
            walletType: 'bank',
            routingNumber: userWalletCP.bankCode,
            accountNumber: userWalletCP.accountNumber,
            accountType: userWalletCP.accountType,
            payorFirstName: userWalletCP.payorFirstName,
          };
        } else if (isWalletTypeOfCreditCard(walletType)) {
          userWalletV2 = {
            ...userWalletV2,
            walletType: 'creditcard',
            creditCardNumber: userWalletCP.cardNumber,
            expirationMonth: userWalletCP.expMonth,
            expirationYear: userWalletCP.expYear,
            creditCardType: cardCPToV2TypesDictionary[userWalletCP.cardType],
            nameOnCard: userWalletCP.payorFirstName,
            postalCode: userWalletCP.postalCode,
          };
        } else {
          throw new Error('unrecognized wallet type');
        }
      } catch (error) {
        handleWalletDTOError({ eventType: 'convertToV2', error, walletId: userWalletCP.id });
        return;
      }
      return userWalletV2;
    },
    convertToGeneric: function (userWalletCP, eventType = 'convertToGeneric') {
      // Used for V2 tokenization/creation and for scs payments
      let userWalletV2 = {};
      try {
        const { walletType } = userWalletCP;

        if (isWalletTypeOfBank(walletType)) {
          userWalletV2 = {
            ...userWalletCP,
            routingNumber: userWalletCP.bankCode,
          };
        } else if (isWalletTypeOfCreditCard(walletType)) {
          userWalletV2 = {
            ...userWalletCP,
            number: userWalletCP.cardNumber,
          };
        } else {
          throw new Error('unrecognized wallet type');
        }
      } catch (error) {
        handleWalletDTOError({ eventType, error, walletId: userWalletCP.id });
        return;
      }
      return userWalletV2;
    },
    convertToSCSPayment: function (paymentData) {
      const { achInfo } = paymentData;
      if (achInfo) {
        const transformedPmData = this.convertToGeneric(
          { ...achInfo, walletType: 'bank' },
          'convertToSCSPayment'
        );
        paymentData = {
          ...paymentData,
          achInfo: transformedPmData,
        };
      }

      return paymentData;
    },
    covertToEftWalletRequestFormat: function (bankDetails) {
      const modifiedBankDetails = {
        ...bankDetails,
        bankCode: bankDetails.institutionNumber,
        branchCode: bankDetails.transitNumber,
        country: 'CA',
      };
      delete modifiedBankDetails.transitNumber;
      delete modifiedBankDetails.institutionNumber;
      return modifiedBankDetails;
    },
    addAddressComponentForRest2Tokenization: function (userWalletCP = {}) {
      const { itemType, multiUse } = userWalletCP;
      const userWalletRest2 = {
        itemType,
        multiUse,
      };

      try {
        const paymentTypeKey = isWalletTypeOfCreditCard(itemType) ? 'card' : 'bankAccount';
        const { address = {} } = userWalletCP[paymentTypeKey];
        const { postalCode, country } = address;

        userWalletRest2[paymentTypeKey] = {
          ...userWalletRest2,
          ...userWalletCP[paymentTypeKey],
          address: {
            addressComponents: [
              { name: 'postalCode', value: postalCode },
              { name: 'country', value: country },
            ],
          },
        };
      } catch (error) {
        handleWalletDTOError({
          eventType: `addAddressComponentForRest2Tokenization`,
          error,
          walletId: userWalletCP.id,
        });
        throw error;
      }
      return userWalletRest2;
    },
  },
};

const userWalletsDTO = {
  helpers: {
    convert: function (walletList = [], convertor) {
      const convertedWallets = walletList
        .map((userWallet) => convertor({ ...userWallet }))
        .filter((wallet) => !!wallet);

      return convertedWallets;
    },
  },
  convertToCPFormat: {
    convertFromRest2: function (userWalletsRest2) {
      return userWalletsDTO.helpers.convert(
        userWalletsRest2,
        walletEntryDTO.convertToCPFormat.convertFromRest2
      );
    },
    convertFromV2: function (userWalletsV2) {
      return userWalletsDTO.helpers.convert(
        userWalletsV2,
        walletEntryDTO.convertToCPFormat.convertFromV2
      );
    },
  },
};

const handleWalletDTOError = ({ eventType, error, walletId }) => {
  splunkReporter.contextual({
    logInfo: { logLevel: 'error', logger },
    event: 'wallet',
    action: eventType,
    activityInfo: {
      status: 'error',
      walletId,
    },
    error: {
      message: error?.message,
    },
  });
};

const isWalletTypeOfCreditCard = (walletType) => {
  return ['card', 'creditcard', 'CARD'].includes(walletType);
};

const isWalletTypeOfBank = (walletType) => {
  return ['bank', 'BANK_ACCOUNT', 'eft'].includes(walletType);
};

export {
  bankAccountV2ToCPTypesDictionary,
  bankAccountCPToV2TypesDictionary,
  cardCPToV2TypesDictionary,
  cardV2ToCPTypesDictionary,
  isWalletTypeOfCreditCard,
  isWalletTypeOfBank,
  walletEntryDTO,
  userWalletsDTO,
};
