import React from 'react';

import Modal from 'components/Core/Modal/Modal';
import { Close } from 'components/Shared/Icons/Icons';
import { colors, fontSize, breakpoints } from 'styles/cp';

export interface Props {
  // eslint-disable-next-line no-unused-vars
  hide(event: React.MouseEvent<HTMLSpanElement, MouseEvent>): void;
  header?: object | string;
  children?: any;
  redesign?: boolean;
  removeDefaultBackground?: boolean;
  customDesignKey?: string;
}

const RoundedModal: React.FC<Props> = ({
  hide,
  children,
  header,
  redesign,
  removeDefaultBackground,
  customDesignKey,
}) => {
  const isFreeTextModal = customDesignKey === 'freeTextModal';
  return (
    <Modal removeDefaultBackground={removeDefaultBackground}>
      <div
        className={`rounded-modal-container ${redesign ? 'redesign' : ''}`}
        data-testid="test-rounded-modal"
      >
        <span className="rounded-modal-header">
          {header && <span className="rounded-modal-message">{header}</span>}
          <span
            className="close-icon"
            onClick={hide}
            data-cy="rounded-modal-btn-close"
            data-testid="rounded-modal-btn-close"
          >
            <Close />
          </span>
        </span>
        {children}
      </div>
      {/* language=scss */}
      <style jsx>{`
        .rounded-modal-container {
          max-width: 100%;
          background-color: ${colors.white};
          padding: 24px 40px;
          /* @growth_exp - freetext */
          border-radius: ${isFreeTextModal ? '12px' : '4px'};
          border: solid 1px ${colors.whiteGray};
          display: block;
          text-align: center;

          @media screen and (max-width: ${breakpoints.sm}) {
            padding: 12px 24px 32px;
          }
        }
        .redesign {
          margin: 16px;
          width: 100%;
          height: 92%;
          position: absolute;
          bottom: 0px;
          margin: auto;
          border-top-right-radius: 30px;
          border-top-left-radius: 30px;
        }

        .rounded-modal-header {
          position: relative;
          display: flex;
          text-align: left;

          @media screen and (max-width: ${breakpoints.sm}) {
            margin-bottom: 16px;
          }
        }

        .close-icon {
          position: absolute;
          right: -16px;
          cursor: pointer;

          @media screen and (max-width: ${breakpoints.sm}) {
            right: -8px;
          }
        }

        .rounded-modal-message {
          font-size: ${fontSize.ml};
          font-family: AvenirNextforINTUIT-Medium;
          padding-top: 16px;
          @media screen and (max-width: ${breakpoints.sm}) {
            font-size: ${fontSize.ms};
            padding-top: 32px;
          }
        }
      `}</style>
    </Modal>
  );
};

export default RoundedModal;
