import App from 'next/app';
import React, { Fragment } from 'react';
import { IntlProvider, addLocaleData } from 'react-intl';
import { Provider } from 'react-redux';

import initializeBusinessLogic from 'businessLogic/initializer';
import GlobalHeader from 'components/Core/Header/GlobalHeader/GlobalHeader';
import ModalWrapper from 'components/Core/Modal/ModalWrapper/ModalWrapper';
import ToastWrapper from 'components/Core/Toast/ToastWrapper/ToastWrapper';
import { FeedbackButton } from 'components/Shared/Feedback/Feedback';
import Logger from 'reporting/Logger';
import { SplunkReporter } from 'reporting/splunk/SplunkReporter';
import HttpClient from 'server/helpers/HttpClient';
import {
  responseErrorInterceptor,
  responseProfilerInterceptor,
} from 'server/middleware/httpInterceptors';
import ReduxSubscribers from 'store/ReduxSubscribers';
import { companyInfoSelectors } from 'store/companyInfo/selectors';
import { initializeStore } from 'store/store';
import { breakpoints, colors, fontSize } from 'styles/cp';
import { popoverStyles } from 'styles/popover';
const splunkReporter = SplunkReporter.getInstance();
const logger = 'pages/_app.page';
const isServer = typeof window === 'undefined';
export const __NEXT_REDUX_STORE__ = '__NEXT_REDUX_STORE__';

function getOrCreateStore(initialState) {
  const businessLogic = initializeBusinessLogic(initialState);
  // Always make a new store if server, otherwise state is shared between requests
  if (isServer) {
    return initializeStore(initialState, businessLogic);
  }

  // Create store if unavailable on the client and set it on the window object
  if (!window[__NEXT_REDUX_STORE__]) {
    window[__NEXT_REDUX_STORE__] = initializeStore(initialState, businessLogic);
  }
  return window[__NEXT_REDUX_STORE__];
}

class _App extends App {
  // eslint-disable-next-line no-unused-vars
  static async getInitialProps(appContext) {
    const { Component: Page } = appContext;

    // initiate redux store
    let reduxStore,
      appInitialState = {};

    // get all props required by app, this is should
    let {
      pageMessages = [],
      pageProps = {},
      initialState: pageInitialState,
    } = await Page.getInitialProps(appContext);

    Object.assign(appInitialState, pageInitialState);

    if (isServer) {
      // Server side, build the store for client usage
      //
      // Get the `locale` and `messages` from the request object on the server.
      // In the browser, use the same values that the server serialized.
      const {
        ctx: { req },
      } = appContext;
      const { locale = 'en-us', allMessages = {}, config, ssrtid = '', featureFlags } = req;

      // the the now to be same value for client and server for react intl
      pageProps.now = Date.now();
      pageProps.pageName = req.pageName;
      // extract the config directly from the request to the intial state
      appInitialState.config = {
        ...config,
        ssrtid,
        bioCatchSessionId: ssrtid && ssrtid.replace(/-/g, ''),
        fullUrl: req.fullUrl,
      };

      if (appInitialState.auth && appInitialState.auth.isUserSignedIn) {
        // CPV2-1273 | DG 2.0 - Prepare the IAM Account Manager in case the user is strongly authenticated.
        const { iamAccountManagerUrl: endpoint } = appInitialState.config.endpoints;
        const { countryCode } = appInitialState.auth;
        const companyLocale = companyInfoSelectors.localeSelector(appInitialState.companyInfo);
        const { companyId } = companyInfoSelectors.localeSelector(appInitialState.insight);

        let queryParams = [`prgn=US`, `aid=qb`];

        if (countryCode) {
          queryParams.push(`rgn=${countryCode}`);
        }

        if (companyId) {
          queryParams.push(`realm=${companyId}`);
        }

        if (companyLocale) {
          // turn the company-locale to the correct format (from en_US to en-us)
          const localeData = companyLocale.split('_');
          const loc = localeData.map((str) => str.toLowerCase()).join('-');
          queryParams.push(`loc=${loc}`);
        }

        appInitialState.config.endpoints.iamAccountManagerUrl = `${endpoint}?${queryParams.join(
          '&'
        )}`;
      }

      // prepare the initial i18n initial state with current locale
      appInitialState.i18n = {
        locale,
      };

      if (featureFlags) {
        appInitialState.featureFlags = featureFlags;
      }

      // extract only relevant messages for desired page for the first rendering
      // we should fetch everything else later on
      if (allMessages && pageMessages && Array.isArray(pageMessages)) {
        // go over all required page messages and get localized values
        pageMessages = pageMessages.reduce((acc, message) => {
          acc[message] = allMessages[message];
          return acc;
        }, {});
      }

      // GlobalHeader messages goes here since we don't get it from the Page itself
      pageMessages['HEADER_SIGN_IN'] = allMessages['HEADER_SIGN_IN'];
      pageMessages['settings.logout'] = allMessages['settings.logout'];

      appInitialState.i18n.status = 'initialized';
      appInitialState.i18n.messages = allMessages;

      // Get or Create the store with `undefined` as initialState
      // This allows you to set a custom default initialState
      // Initialize the state with page messages and locale in i18n
      reduxStore = initializeStore(appInitialState);
    } else {
      // client side - take it from local variable
      reduxStore = window[__NEXT_REDUX_STORE__];
    }

    // Provide the store to getInitialProps of pages
    appContext.reduxStore = reduxStore;

    return {
      pageProps,
      initialReduxState: reduxStore.getState(),
    };
  }

  constructor(props) {
    super(props);
    this.reduxStore = getOrCreateStore(props.initialReduxState);
    this.state = { intlLoaded: false, intlLoadAttemptCounter: 0 };

    if (!isServer) {
      // configure Logger and reporters
      const { config } = this.reduxStore.getState();
      Logger.logLevel = config.logLevel;
      Logger.debug('initiating Logger for client side');

      // Assign Network Profiling
      const httpClient = HttpClient.getInstance();
      httpClient.registerResponseInterceptor(responseProfilerInterceptor);
      httpClient.registerResponseErrorInterceptor(responseErrorInterceptor);
      httpClient.setReqTimeout(config.clientReqTimeout);

      // set axios retry plugin
      httpClient.setAxiosRetry();

      this.BioCatchInit(config.bioCatchSessionId);
    }
  }
  BioCatchInit(bioCatchSessionId) {
    if (typeof bioCatchSessionId !== 'string' || bioCatchSessionId.length === 0) {
      splunkReporter.contextual({
        logInfo: { logLevel: 'warn', logger },
        event: 'risk',
        action: 'bioCatchProfiling',
        activityInfo: {
          screen: '_app',
        },
        error: {
          message: 'missing bioCatch session id',
        },
      });
    }
    // The cdApi is loaded asynchroniously from the biocatch script on our CDN
    if (window && window['cdApi']) {
      window['cdApi'].setCustomerSessionId(bioCatchSessionId);
    } else if (window) {
      // in case the cdApi from biocatch hasn't loaded yet, wait another second and look again.
      setTimeout(() => {
        this.BioCatchInit(bioCatchSessionId); // this checks for cdApi again and tries to set bscid
      }, 1000);
    }
  }

  componentDidCatch(error, errorInfo) {
    try {
      let blockAppDidCatchLog = true;

      if (this.reduxStore && this.reduxStore.getState) {
        blockAppDidCatchLog =
          this.reduxStore.getState()?.featureFlags?.['SBSEG-CP-block-app-did-catch-log'];
      }

      if (blockAppDidCatchLog) return;

      splunkReporter.contextual({
        logInfo: { logLevel: 'error', logger },
        event: 'viewSale',
        action: 'render',
        activityInfo: {
          screen: '_app',
        },
        error: {
          message: error?.message,
          fullError: JSON.stringify(error, Object.getOwnPropertyNames(error)),
          fullErrorInfo: JSON.stringify(errorInfo),
          stack: errorInfo,
          originalStack: error?.stack,
        },
      });
    } catch (error) {
      splunkReporter.contextual({
        logInfo: { logLevel: 'error', logger },
        event: 'viewSale',
        action: 'render',
        activityInfo: {
          screen: '_app',
          isComponentDidCatchInApp: true,
        },
        error: error,
      });
    }
  }

  updateIntl = () => {
    if (window.ReactIntlLocaleData) {
      Object.keys(window.ReactIntlLocaleData).forEach((lang) => {
        addLocaleData(window.ReactIntlLocaleData[lang]);
        this.setState({ intlLoaded: true });
      });
    } else {
      setTimeout(() => {
        if (!this.state.intlLoaded) {
          this.updateIntl();
          this.setState({
            intlLoadAttemptCounter: this.state.intlLoadAttemptCounter + 1,
          });
        }
      }, 0);
    }
  };

  render() {
    const { reduxStore } = this;
    const { messages, locale } = reduxStore.getState().i18n;
    const { featureFlags } = reduxStore.getState();
    const { Component: Page, pageProps } = this.props;

    const [lang, country] = locale.split('-');

    const intlProviderLocale = locale === 'en-IN' ? locale : lang;

    const isCounterReachedLimit = this.state.intlLoadAttemptCounter >= 2;
    const isClientSide = !isServer;
    const isI18nFixFFEnabled = featureFlags && featureFlags['use-i18n-fix'];

    if (isI18nFixFFEnabled && !isCounterReachedLimit && !this.state.intlLoaded && isClientSide) {
      this.updateIntl();
      return null;
    }

    return (
      <Fragment>
        {/*language=SCSS*/}
        <style jsx global>{`
          *,
          ::after,
          ::before {
            box-sizing: border-box;
          }

          @font-face {
            font-family: AvenirNextforINTUIT-Bold;
            src: url('https://static.cns-icn-prod.a.intuit.com/fonts/AvenirNext-forINTUIT-Web-Fonts/AvenirNext+forINTUIT+W05+Bold_web.woff2')
                format('woff2'),
              local('Helvetica Neue Bold'), local('Arial');
          }
          @font-face {
            font-family: AvenirNextforINTUIT-Demi;
            src: url('https://static.cns-icn-prod.a.intuit.com/fonts/AvenirNext-forINTUIT-Web-Fonts/AvenirNext+forINTUIT+W05+Demi_web.woff2')
                format('woff2'),
              local('Helvetica Neue'), local('Arial');
          }
          @font-face {
            font-family: AvenirNextforINTUIT-Medium;
            src: url('https://static.cns-icn-prod.a.intuit.com/fonts/AvenirNext-forINTUIT-Web-Fonts/AvenirNext+forINTUIT+W05+Mediu_web.woff2')
                format('woff2'),
              local('Helvetica Neue'), local('Arial');
          }
          @font-face {
            font-family: AvenirNextforINTUIT-Regular;
            src: url('https://static.cns-icn-prod.a.intuit.com/fonts/AvenirNext-forINTUIT-Web-Fonts/AvenirNext+forINTUIT+W05+Rg_web.woff2')
                format('woff2'),
              local('Helvetica Neue'), local('Arial');
          }

          body {
            margin: 0;
            font-family: AvenirNextforINTUIT-Regular, 'Helvetica Neue', Arial, sans-serif;
            font-size: 1rem;
            line-height: 1.5;
            color: ${colors.darkBlack};
            text-align: left;
            background-color: #fff;

            &.blur {
              filter: blur(6px);
            }
          }

          html body {
            background-color: ${colors.offWhite};
            width: 100vw;
            height: 100vh;
          }

          body.modal-open {
            .main-layout {
              filter: blur(8px);
            }
            overflow: hidden;
            position: fixed;
          }

          .custom_class {
            margin: 0;
          }

          .main-layout {
            display: flex;
            flex-direction: column;
            position: relative;
            width: 100vw;
            height: 100vh;
          }

          .main-wrapper {
            word-break: break-word;
            max-width: ${breakpoints.xl};
            width: 100%;
            margin: 20px auto;
            padding: 0 20px;

            :global(.balance-due) {
              text-align: right;
            }

            @media screen and (max-width: ${breakpoints.md}) {
              margin: 0;
              padding: 3px;
            }
          }

          .main-wrapper-right {
            word-break: break-word;
            max-width: ${breakpoints.xl};
            width: 100%;
            margin: 0 auto;
            padding: 0 20px;

            :global(.balance-due) {
              text-align: right;
            }

            @media screen and (max-width: ${breakpoints.md}) {
              margin: 3px;
              padding: 3px;
            }
          }

          button,
          a {
            user-select: none;
            color: ${colors.gray02};
          }
          b {
            font-family: AvenirNextforINTUIT-Demi;
          }

          .fade-in {
            opacity: 1;
            animation-name: fadeInOpacity;
            animation-iteration-count: 1;
            animation-timing-function: linear;
            animation-duration: 0.2s;
          }

          .fade-out {
            opacity: 0;
            animation-name: fadeInOpacity;
            animation-iteration-count: 1;
            animation-timing-function: linear;
            animation-duration: 0.2s;
          }

          .expand {
            animation-name: expandAnim;
            animation-iteration-count: 1;
            animation-timing-function: linear;
            animation-duration: 0.5s;
          }

          .collapse {
            animation-name: collapseAnim;
            animation-iteration-count: 1;
            animation-timing-function: linear;
            animation-duration: 0.5s;
          }

          @keyframes expandAnim {
            0% {
              height: auto;
            }
            100% {
              height: 0;
            }
          }

          @keyframes collapseAnim {
            0% {
              height: 0;
            }
            100% {
              height: auto;
            }
          }

          @keyframes fadeInOpacity {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }

          .flex {
            display: flex;
            flex-direction: row;
          }

          .flex-any {
            flex: 1;

            &.flex-min {
              flex: 0;
            }

            &.trunc {
              min-width: 0;

              label {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }

          .error-message {
            color: ${colors.error};
            font-size: ${fontSize.xxs};
            font-style: italic;
          }

          @keyframes idsTooltipFadeIn {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }

          .cp-tooltip-wrapper {
            animation-name: idsTooltipFadeIn;
            animation-duration: 0.25s;
            animation-timing-function: ease;
            animation-delay: 0s;
            animation-iteration-count: 1;
            animation-direction: normal;
            animation-fill-mode: none;
            animation-play-state: running;
          }

          .cp-tooltip {
            background: ${colors.gray};
            padding: 16px;
            font-family: AvenirNextforINTUIT-Regular;
            font-size: ${fontSize.xs};
            color: ${colors.white};

            max-width: 400px;
            min-width: 80px;
            opacity: 1;
            border-radius: 4px;
          }

          .cp-link {
            a {
              text-decoration: underline;
            }
          }

          .cp-menu-wrapper {
            background: ${colors.white};
            border-width: 1px;
            border-style: solid;
            border-color: ${popoverStyles.whitePopoverArrowOuterColor};
            color: ${colors.gray01};

            animation-name: idsTooltipFadeIn;
            animation-duration: 0.25s;
            animation-timing-function: ease;
            animation-delay: 0s;
            animation-iteration-count: 1;
            animation-direction: normal;
            animation-fill-mode: none;
            animation-play-state: running;
          }

          .cp-menu {
            font-family: AvenirNextforINTUIT-Regular;
            font-size: ${fontSize.xs};

            min-width: 80px;
            opacity: 1;
            border-radius: 4px;

            ul {
              padding: 0;
              margin: 0;
              list-style: none;

              li {
                cursor: pointer;
                padding: 8px 18px;
                border-bottom: 1px solid ${popoverStyles.menuItemDividerBorderColor};

                &:last-child {
                  border: none;
                }
              }
            }
          }
        `}</style>

        <IntlProvider
          locale={intlProviderLocale}
          messages={messages}
          now={pageProps.now}
          timeZone="utc"
          textComponent="span"
        >
          <Provider store={reduxStore}>
            <ReduxSubscribers />
            <div className="main-layout">
              <GlobalHeader hideUserBar={pageProps.hideUserBar} />

              <Page {...pageProps} initialReduxState={this.props.initialReduxState} />

              {pageProps && !pageProps.hideFeedBackLink && country === 'us' && (
                <FeedbackButton transactionType={this.props.initialReduxState.sale.type} />
              )}
            </div>
            <ModalWrapper />
            <ToastWrapper />
          </Provider>
        </IntlProvider>
      </Fragment>
    );
  }
}

export default _App;
