// @ts-nocheck
import { PayloadAction } from '@reduxjs/toolkit';
import { RawAxiosRequestHeaders, AxiosResponse } from 'axios';
import { Dispatch } from 'redux';

import {
  getApplePayPaymentPayloadForCpServer,
  getBankPaymentPayloadForCpServer,
  getCCPaymentPayloadForCpServer,
  getNanopayBankPaymentPayloadForCpServer,
  getZipCode,
} from './cpServerTranslators';
import {
  isAmexAccountEnabled,
  schedulePaymentHelpers,
  subscriptionPaymentHelpers,
} from './helpers';
import {
  closePayPalFlow,
  getPayPalCheckOut,
  openPayPalSandBox,
  payPalCheckoutSetup,
  startPayPalFlow,
} from './payPal';
import { lockForriskProfileToken, reportPrePayment } from './riskActions';

import { reportPaymentNewSyntax } from 'businessLogic/Insight/Reporting';
import {
  isApplePayEnabled as isApplePayEnabledBL,
  makeApplePayPayment as openApplePayPaymentBL,
} from 'businessLogic/Payment/ApplePay';
import { ApplePayInfo } from 'businessLogic/Payment/ApplePay/ApplePayTypes';
import { closeAllPayPalButtons, generatePaymentSucessPayload } from 'businessLogic/Payment/Common';
import { displayGratuityFeature, eligibleGratuityAmount } from 'businessLogic/Payment/Gratuity';
import { concatPaymentMethodWithSubType, isMAIPasPDF } from 'businessLogic/Payment/helpers';
import { getHeadersForSSRRequest, logIfMissingriskProfileToken } from 'businessLogic/utils';
import ActionModal from 'components/Shared/ActionModal/ActionModal';
import Logger from 'reporting/Logger';
import SegmentIO from 'reporting/SegmentIO';
import HttpClient from 'server/helpers/HttpClient';
import convertLocaleToCountry from 'server/helpers/convertLocaleToCountry';
import { getUUID } from 'server/helpers/uuidGenerator';
import {
  formatMaskedAccountNumber,
  isApplePayPaymentMethod,
  isBankAccountPaymentMethod,
  isCreditCardPaymentMethod,
  isNanopayPaymentMethod,
  isPayPalPaymentMethod,
  PaymentAmountValidator,
} from 'shared/PaymentHelpers';
import { mark, scsPaymentEnabled } from 'shared/clientUtils';
import { promiseTimeout } from 'shared/timeoutPromise';
import { companyInfoSelectors } from 'store/companyInfo/selectors';
import { insightSelectors } from 'store/insight/selectors';
import { isPPAAMPayLaterEnabled } from 'store/ixp/selector';
import { modalActions } from 'store/modal/slice';
import { saleSelectors } from 'store/sale/selectors';
import { updateNewBalance, updateSubscriptionInfo } from 'store/sale/slice';
import { RootState } from 'store/store';
import { sliceFactory, thunkActionFactory } from 'store/utils';
import { networkPaymentErrorTranslator } from 'store/wallet/errorTranslators';
import {
  fetchWallets,
  REMOTE_BUTTON_ACCESS_FUNCTIONALITY,
  walletActions,
} from 'store/wallet/slice';
import { CompanyInfo } from 'types/CompanyInfo';
import { ErrorWithResponse } from 'types/Error';
import { FeatureFlags } from 'types/FeatureFlags';
import { Nanopay } from 'types/Nanopay';
import { Payment } from 'types/Payment';
import { Sale } from 'types/Sale';
import { UserWalletBank, UserWalletCard, Wallet } from 'types/Wallet';
import {
  WALLET_MAP,
  TXN_MAP,
  PAYMENT_MAP,
  CardType,
  PaymentMethod,
  PAYMENT_ERROR_TYPES,
} from 'types/constants';

const httpClient = HttpClient.getInstance();

const initialState = {
  gratuityValue: 0,
  allowOverPay: true,
  paymentStatus: null,
  requestIdInProgress: null,
  paymentError: null,
  paymentSessionCounter: 0,
  isPaymentSessionDirty: false,
  successfulPaymentCounter: 0,
  lastSuccessfulPaymentResponse: null,
  urlToInvoicePage: null,
  paidInvoices: null,
  isPayPalCommerceInvoice: false,
  isDateScheduled: false,
  isAutoPayOn: false,
  contactDetailsName: '',
  contactDetailsEmail: '',
  isContactDetailsNameValid: true,
  isContactDetailsEmailValid: true,
} as Payment;

const { reducer, actions } = sliceFactory({
  name: 'payment',
  initialState,
  reducers: {
    gratuityAmountChange(
      state: Pick<Payment, 'inputAmount' | 'paymentMethodType' | 'allowOverPay' | 'balanceAmount'>,
      action: PayloadAction<{
        gratuityAmount: number;
        region: CompanyInfo['region'];
        featureFlags: FeatureFlags;
      }>
    ) {
      let {
        payload: { gratuityAmount = 0, region, featureFlags },
      } = action;

      const { inputAmount, paymentMethodType, allowOverPay, balanceAmount } = state;

      let total = 0;
      let validGratuityAmount = 0;
      let isAmountValid = false;
      try {
        validGratuityAmount = Number(gratuityAmount.toFixed(2));
        total = Number((inputAmount + validGratuityAmount).toFixed(2));
        const amountData = {
          amount: inputAmount,
          balanceAmount: balanceAmount,
          paymentMethod: paymentMethodType,
          allowOverPay: allowOverPay,
          gratuityValue: validGratuityAmount,
          transactionType: undefined,
          region,
          featureFlags,
        };
        isAmountValid = PaymentAmountValidator.validate(amountData);
      } catch (error) {
        return state;
      }

      return {
        ...state,
        gratuityValue: validGratuityAmount,
        isAmountValid,
        amount: total,
      };
    },
    paymentAmountChange(
      state: Pick<Payment, 'paymentMethodType' | 'allowOverPay' | 'gratuityValue' | 'inputAmount'>,
      action: PayloadAction<{
        balanceAmount: number;
        newAmount: number;
        region: CompanyInfo['region'];
        featureFlags: FeatureFlags;
      }>
    ) {
      const {
        payload: { balanceAmount, newAmount, region, featureFlags },
      } = action;
      const { paymentMethodType, allowOverPay, gratuityValue } = state;
      const amountData = {
        amount: newAmount,
        balanceAmount: balanceAmount,
        paymentMethod: paymentMethodType,
        allowOverPay: allowOverPay,
        gratuityValue: gratuityValue,
        transactionType: undefined,
        region,
        featureFlags,
      };

      if (state.inputAmount === newAmount) {
        return state;
      }

      const isAmountValid = PaymentAmountValidator.validate(amountData);
      const inputAmount = newAmount;
      return {
        ...state,
        amount: inputAmount,
        inputAmount,
        isAmountValid,
      };
    },
    defaultPaymentAmountChange(
      state: Pick<Payment, 'defaultInputAmount'>,
      action: PayloadAction<Pick<Payment, 'defaultInputAmount'>>
    ) {
      state.defaultInputAmount = action.payload.defaultInputAmount;
    },
    paymentMethodTypeChange(
      state: Pick<
        Payment,
        | 'defaultInputAmount'
        | 'inputAmount'
        | 'allowOverPay'
        | 'gratuityValue'
        | 'paymentMethodSubType'
      >,
      action: PayloadAction<{
        paymentMethodType?: Payment['prevPaymentMethodType'];
        newPaymentMethodType?: Payment['prevPaymentMethodType'];
        balanceAmount: number;
        paymentMethodSubType: PaymentMethod['paymentMethodSubType'];
        region: CompanyInfo['region'];
        featureFlags: FeatureFlags;
      }>
    ) {
      const {
        newPaymentMethodType,
        paymentMethodType,
        balanceAmount,
        paymentMethodSubType,
        region,
        featureFlags,
      } = action.payload;

      const amountData = {
        amount: state.inputAmount,
        balanceAmount,
        paymentMethod: newPaymentMethodType,
        allowOverPay: state.allowOverPay,
        gratuityValue: state.gratuityValue,
        transactionType: undefined,
        region,
        featureFlags,
      };
      return {
        ...state,
        paymentError: null,
        paymentMethodType: newPaymentMethodType,
        prevPaymentMethodType: paymentMethodType,
        isAmountValid: PaymentAmountValidator.validate(amountData),
        paymentMethodSubType,
      };
    },
    setScheduledDate(
      state: Pick<Payment, 'scheduledDate' | 'isDateScheduled'>,
      action: PayloadAction<{ scheduledDate: Date; isDateScheduled: boolean }>
    ) {
      const { scheduledDate, isDateScheduled } = action.payload;
      state.scheduledDate = scheduledDate;
      state.isDateScheduled = isDateScheduled;
    },
    setPayPalCommerceIdempotentKeys(
      state: Pick<Payment, 'ppCreateOrderKey' | 'ppCaptureOrderKey'>,
      action: PayloadAction<Pick<Payment, 'ppCreateOrderKey' | 'ppCaptureOrderKey'>>
    ) {
      state.ppCreateOrderKey = action.payload.ppCreateOrderKey;
      state.ppCaptureOrderKey = action.payload.ppCaptureOrderKey;
    },
    rejectPaymentRequest(
      state: Pick<Payment, 'paymentRejectType'>,
      action: PayloadAction<Payment['paymentRejectType'] | undefined>
    ) {
      state.paymentRejectType = action.payload;
    },
    createPayment(state: Pick<Payment, 'paymentError' | 'paymentStatus'>) {
      state.paymentError = null;
      state.paymentStatus = TXN_MAP.STATUS.IN_PROGRESS;
    },
    createPaymentError(
      state: Pick<Payment, 'paymentSessionCounter'>,
      action: PayloadAction<{ error: ErrorWithResponse; featureFlags?: FeatureFlags }>
    ) {
      let { error, featureFlags } = action.payload;
      if (error && error.response) {
        error = error.response;
      }
      const { status = {}, data = {} } = error;

      return {
        ...state,
        paymentStatus:
          data && data.paymentStatus === TXN_MAP.STATUS.DECLINED
            ? data.paymentStatus
            : TXN_MAP.STATUS.ERROR,
        paymentError: networkPaymentErrorTranslator({ status, data, featureFlags }),
        paymentSessionCounter: state.paymentSessionCounter + 1,
      };
    },
    tokenizeWallet(state: Pick<Payment, 'paymentError' | 'paymentStatus'>) {
      state.paymentError = null;
      state.paymentStatus = TXN_MAP.STATUS.IN_PROGRESS;
    },
    tokenizeWalletError(
      state: Pick<Payment, 'paymentError' | 'paymentStatus'>,
      action: PayloadAction<{ error: Payment['paymentError'] }>
    ) {
      state.paymentError = action.payload.error;
      state.paymentStatus = TXN_MAP.STATUS.ERROR;
    },
    validateContactDetailsName(
      state: Pick<Payment, 'isContactDetailsNameValid'>,
      action: PayloadAction<Payment['isContactDetailsNameValid']>
    ) {
      state.isContactDetailsNameValid = action.payload;
    },
    validateContactDetailsEmail(
      state: Pick<Payment, 'isContactDetailsEmailValid'>,
      action: PayloadAction<Payment['isContactDetailsEmailValid']>
    ) {
      state.isContactDetailsEmailValid = action.payload;
    },
    cancelPayment(state: Pick<Payment, 'paymentError' | 'paymentStatus'>) {
      state.paymentError = null;
      state.paymentStatus = null;
    },
    createSchedulePaymentSuccess(
      state: Pick<Payment, 'paymentSessionCounter' | 'successfulPaymentCounter'>,
      action: PayloadAction<
        { result: { data: Payment['lastSuccessfulSchedulePaymentResponse'] } } | undefined
      >
    ) {
      // This function gets the payment result and object that contain the account number in case of form
      const { data } = (action.payload && action.payload.result) || {};

      return {
        ...state,
        paymentStatus: TXN_MAP.STATUS.SUCCESS,
        paymentError: null,
        paymentSessionCounter: state.paymentSessionCounter + 1,
        successfulPaymentCounter: state.successfulPaymentCounter + 1,
        lastSuccessfulSchedulePaymentResponse: data,
      };
    },
    storePaidInvoices(
      state: Pick<Payment, 'paidInvoices'>,
      action: PayloadAction<Pick<Payment, 'paidInvoices'>>
    ) {
      state.paidInvoices = action.payload.paidInvoices;
    },
    createPaymentSuccess(
      state: Pick<
        Payment,
        'paymentMethodType' | 'successfulPaymentCounter' | 'paymentSessionCounter'
      >,
      action: PayloadAction<{ result: AxiosResponse }>
    ) {
      const successParams = {
        payload: action.payload,
        paymentMethodType: state.paymentMethodType,
        successfulPaymentCounter: state.successfulPaymentCounter,
        paymentSessionCounter: state.paymentSessionCounter,
      };
      const paymentPayload = generatePaymentSucessPayload(successParams as any);

      return {
        ...state,
        ...paymentPayload,
      };
    },
    hideSuccessScreen(state: Pick<Payment, 'lastSuccessfulPaymentResponse'>) {
      state.lastSuccessfulPaymentResponse = 'back_from_success';
    },
    setIsAutoPayOn(
      state: Pick<Payment, 'isAutoPayOn'>,
      action: PayloadAction<Payment['isAutoPayOn']>
    ) {
      state.isAutoPayOn = action.payload;
    },
    setPayPalOrderId(
      state: Pick<Payment, 'intuitOrderId'>,
      action: PayloadAction<{ intuitOrderId: Payment['intuitOrderId'] } | undefined>
    ) {
      const { intuitOrderId } = (action && action.payload) || {};
      if (intuitOrderId) {
        state.intuitOrderId = intuitOrderId;
      }
    },
    disablePartialAndOverPay(state: Pick<Payment, 'allowOverPay' | 'partialPaymentEnabled'>) {
      state.allowOverPay = false;
      state.partialPaymentEnabled = false;
    },
    setContactDetailsName(
      state: Pick<Payment, 'contactDetailsName'>,
      action: PayloadAction<Payment['contactDetailsName']>
    ) {
      state.contactDetailsName = action.payload;
    },
    setContactDetailsEmail(
      state: Pick<Payment, 'contactDetailsEmail'>,
      action: PayloadAction<Payment['contactDetailsEmail']>
    ) {
      state.contactDetailsEmail = action.payload;
    },
    shouldShowPaypalAndVenmo(
      state: Pick<Payment, 'paymentMethodType'>,
      action: PayloadAction<{ paymentMethodType: Payment['paymentMethodType'] }>
    ) {
      const { paymentMethodType } = (action && action.payload) || {};
      state.paymentMethodType = paymentMethodType;
    },
    setIsSavePaymentMethodChecked(
      state: Pick<Payment, 'isSavePaymentMethodChecked' | 'paymentRejectType'>,
      action: PayloadAction<Payment['isSavePaymentMethodChecked']>
    ) {
      state.isSavePaymentMethodChecked = action.payload;
      state.paymentRejectType = null;
    },
    setSubscriptionUpdateRequired(
      state: Pick<Payment, 'isSubscriptionUpdatePaymentMethodRequired'>,
      action: PayloadAction<Payment['isSubscriptionUpdatePaymentMethodRequired']>
    ) {
      state.isSubscriptionUpdatePaymentMethodRequired = action.payload;
    },
    updateCountry(state: Pick<Payment, 'country'>, action: PayloadAction<Payment['country']>) {
      state.country = action.payload;
    },
  },
});

export const paymentReducer = reducer;
export const paymentActions = actions;

export const gratuityAmountChange = thunkActionFactory<number | undefined>(
  ({ payload = 0, dispatch, state }) => {
    const {
      featureFlags,
      companyInfo: { region },
    } = state;
    const gratuityValue = featureFlags && featureFlags['gratuity-enabled'] ? payload : 0;

    dispatch(
      paymentActions.gratuityAmountChange({
        gratuityAmount: gratuityValue,
        region,
        featureFlags,
      })
    );
  }
);

export const paymentAmountChange = thunkActionFactory<number>(({ payload, dispatch, state }) => {
  const {
    sale = {} as Sale,
    companyInfo: { region },
    featureFlags,
  } = state;
  const balanceAmount = saleSelectors.balanceSelector(sale);

  dispatch(
    paymentActions.paymentAmountChange({
      newAmount: payload,
      balanceAmount,
      region,
      featureFlags,
    })
  );
});

export const paymentAmountReset = thunkActionFactory(({ dispatch, state }) => {
  const {
    sale,
    payment: { defaultInputAmount },
    companyInfo: { region },
    featureFlags,
  } = state;

  const balanceAmount = saleSelectors.balanceSelector(sale);

  dispatch(
    paymentActions.paymentAmountChange({
      newAmount: defaultInputAmount,
      balanceAmount: balanceAmount,
      region,
      featureFlags,
    })
  );
});

export const setDefaultInputAmount = thunkActionFactory(({ dispatch, state }) => {
  const {
    companyInfo: { region },
    sale,
    payment: { paymentMethodType, allowOverPay },
    featureFlags,
  } = state;

  const balanceAmount = saleSelectors.balanceSelector(sale);

  dispatch(
    paymentActions.defaultPaymentAmountChange({
      defaultInputAmount: getDefaultPaymentAmount({
        balanceAmount,
        paymentMethodType,
        allowOverPay,
        region,
        featureFlags,
      }),
    })
  );
});

const getDefaultPaymentAmount = ({
  balanceAmount,
  paymentMethodType,
  allowOverPay,
  region,
  featureFlags,
}:
  | Pick<Payment, 'balanceAmount' | 'paymentMethodType' | 'allowOverPay'>
  | Pick<CompanyInfo, 'region'>
  | FeatureFlags) => {
  const maxPaymentAmount = PaymentAmountValidator.getMaxPaymentAmount(
    balanceAmount,
    paymentMethodType,
    allowOverPay,
    region,
    featureFlags
  );
  return Math.min(balanceAmount, maxPaymentAmount);
};

export const paymentMethodTypeChange = thunkActionFactory<{
  paymentMethodType: Payment['paymentMethodType'];
  paymentMethodSubType?: Payment['paymentMethodSubType'];
}>(({ payload, dispatch, state }) => {
  const { paymentMethodType: newPaymentMethodType, paymentMethodSubType: newPaymentMethodSubType } =
    payload;
  const {
    sale,
    payment: { paymentMethodType, isDateScheduled, paymentMethodSubType },
    companyInfo: { region },
    featureFlags,
  } = state;
  const balanceAmount = saleSelectors.balanceSelector(sale);
  closeAllPayPalButtons(paymentMethodType, newPaymentMethodType);
  setTimeout(() => {
    SegmentIO.changePaymentMethod(
      concatPaymentMethodWithSubType(paymentMethodType, paymentMethodSubType),
      concatPaymentMethodWithSubType(newPaymentMethodType, newPaymentMethodSubType)
    );
  }, 0);
  dispatch(
    paymentActions.paymentMethodTypeChange({
      paymentMethodType,
      newPaymentMethodType,
      balanceAmount,
      paymentMethodSubType: newPaymentMethodSubType,
      region,
      featureFlags,
    })
  );
  dispatch(setDefaultInputAmount());
  if (
    isDateScheduled &&
    (['paypal_ppaam', 'venmo'] as Array<Payment['paymentMethodType']>).includes(
      newPaymentMethodType
    )
  ) {
    dispatch(
      paymentActions.setScheduledDate({
        scheduledDate: new Date(Date.now()),
        isDateScheduled: false,
      })
    );
  }
});

export const initializeApplePay = thunkActionFactory(async ({ dispatch, state }) => {
  let balanceAmount: number,
    token: string,
    initPayErrorTemplate: {
      EventName: string;
      EventType: string;
      ssrtid: string;
      token: string;
      userAgent: string;
    };
  try {
    const {
      wallet,
      payment,
      i18n,
      config: { ssrtid, applePay },
      sale,
      insight,
      companyInfo: { region },
      featureFlags,
    } = state;

    // We don't allow ap on subscription invoices
    if (saleSelectors.isSubscription(sale)) {
      dispatch(walletActions.detectApplePayEnabledStatusFinished());
      return;
    }
    initPayErrorTemplate = {
      EventName: 'CPV2ApplePayError',
      EventType: 'initializeApplePay()',
      ssrtid,
      token,
      userAgent: window?.navigator?.userAgent,
    };

    balanceAmount = saleSelectors.balanceSelector(sale);
    // Token is assigned but never used. Should we remove it?
    token = insightSelectors.tokenSelector(insight);

    if (wallet && payment) {
      const { enabledPaymentMethods } = wallet;
      const locale = i18n.locale;
      const applePayConfig = applePay;
      const { paymentStatus } = payment;
      const applePayPromiseStart = Date.now();
      const handleApplePayEnabled = () => {
        dispatch({
          type: walletActions.addApplePayEnabledPaymentMethod.type,
        });
        if (paymentStatus !== 'success') {
          let newPaymentMethodType: Payment['paymentMethodType'];
          newPaymentMethodType = 'ap';

          if (payment.paymentMethodType !== newPaymentMethodType) {
            dispatch(
              paymentActions.paymentMethodTypeChange({
                newPaymentMethodType,
                balanceAmount,
                region,
                featureFlags,
              })
            );
          }
        }
      };
      let isApplePayEnabledPromise: Promise<boolean>;

      isApplePayEnabledPromise = isApplePayEnabledBL({
        enabledPaymentMethods,
        locale,
        applePayConfig,
      });

      isApplePayEnabledPromise = promiseTimeout(
        applePayConfig.timeout || 10000,
        isApplePayEnabledPromise
      );

      try {
        if (await isApplePayEnabledPromise) {
          handleApplePayEnabled();
          const applePayPromiseRum = Date.now() - applePayPromiseStart;
          Logger.info({
            ...initPayErrorTemplate,
            applePayPromiseRum,
          });
        }
      } catch (e) {
        const applePayPromiseRum = Date.now() - applePayPromiseStart;
        Logger.info({
          ...initPayErrorTemplate,
          EventMessage: (e as Error).message,
          applePayPromiseRum,
        });
      }

      dispatch(walletActions.detectApplePayEnabledStatusFinished());
    }
  } catch (e) {
    dispatch(walletActions.detectApplePayEnabledStatusFinished());
    Logger.error({
      ...initPayErrorTemplate,
      EventMessage: (e as ErrorWithResponse).message,
      intuit_tid: (e as ErrorWithResponse).config
        ? (e as ErrorWithResponse).config?.headers['intuit_tid']
        : null,
    });
  }
});

export const initializePayPal = thunkActionFactory(({ dispatch, state }) => {
  try {
    const { auth, config, payment, insight, sale, companyInfo, featureFlags } = state;

    if (payment && sale && companyInfo && insight) {
      const { isPayPalCommerceInvoice } = payment;
      const {
        view2pay: { isPayPalEnabled },
      } = insight;
      const {
        receivable: { balance: balanceAmount },
      } = sale;

      if (!isPayPalCommerceInvoice && isPayPalEnabled) {
        payPalCheckoutSetup(sale, insight, companyInfo, config, auth, payment);
        return;
      }
      const allowedPaymentMethods = ['paypal'] as Wallet['enabledPaymentMethods'];
      const newPaymentMethodType = 'pp';
      if (isPayPalCommerceInvoice && typeof window !== 'undefined') {
        if (
          !window.paypal ||
          !window.paypal.HostedFields ||
          window.paypal.HostedFields.isEligible() !== true
        ) {
          dispatch(walletActions.changeEnabledPaymentMethods({ allowedPaymentMethods }));
          dispatch(
            paymentActions.paymentMethodTypeChange({
              newPaymentMethodType,
              balanceAmount,
              featureFlags,
            })
          );
        }
        if (featureFlags && featureFlags['set-uuids-for-paypal']) {
          dispatch(
            paymentActions.setPayPalCommerceIdempotentKeys({
              ppCreateOrderKey: getUUID(),
              ppCaptureOrderKey: getUUID(),
            })
          );
        }
        dispatch({
          type: walletActions.detectPayPalEnabledStatusFinished.type,
        });
      }
    }
  } catch (e) {
    Logger.error({
      EventName: 'CPV2PayPalCommerceError',
      EventType: 'initializePayPal()',
      EventMessage: (e as ErrorWithResponse).message,
      intuit_tid: (e as ErrorWithResponse).config
        ? (e as ErrorWithResponse).config?.headers['intuit_tid']
        : null,
    });
  }
});

export const initializePPAAMPayLater = () => async (dispatch, getState) => {
  const { wallet, payment, ixp, featureFlags } = getState();
  const shouldNotShowPPAAMPayLater =
    (wallet && !Array.isArray(wallet.enabledPaymentMethods)) ||
    !isPPAAMPayLaterEnabled({ ixp, featureFlags }) ||
    !payment ||
    (wallet && !wallet.enabledPaymentMethods.includes('paypal_ppaam'));
  if (shouldNotShowPPAAMPayLater) {
    return;
  }

  dispatch(walletActions.updateIsPPAAMInstallmentsEnabled());
  dispatch(
    walletActions.changeEnabledPaymentMethods({
      allowedPaymentMethods: [...wallet.enabledPaymentMethods, 'paypal_ppaam paylater'],
    })
  );
  dispatch(walletActions.mergeCCAndDCIfMoreThen6PMs());
};

export const onPayButtonClicked = thunkActionFactory(({ dispatch, state }) => {
  const {
    payment: { paymentMethodType, paymentStatus },
    wallet,
    sale,
    payment,
  } = state;
  // Reject payment when...
  const { fetchWalletStatus } = wallet;

  const isFetchingWallets = fetchWalletStatus === WALLET_MAP.FETCH_STATUS.FETCHING;
  const isPaymentInProgress = paymentStatus === TXN_MAP.STATUS.IN_PROGRESS;

  const isSubscription = saleSelectors.isSubscription(sale);
  const subscriptionInvoiceNotConsented = isSubscription && !payment.isSavePaymentMethodChecked;

  if (isFetchingWallets || isPaymentInProgress) {
    dispatch(paymentActions.rejectPaymentRequest(undefined));
  }

  if (subscriptionInvoiceNotConsented) {
    dispatch(paymentActions.rejectPaymentRequest(PAYMENT_MAP.REJECT_TYPES.MISSING_CONSENT));
  }

  mark('pay button clicked');
  // dispatch({
  //   type: actionTypes.ON_PAY_BUTTON_CLICKED,
  // });

  if (isBankAccountPaymentMethod(paymentMethodType)) {
    const { selectedWalletId } = wallet;

    // Is the user on the ACHForm?
    if (selectedWalletId === 'AddBank' || selectedWalletId === null) {
      // Remotely trigger submission lifecycle of the Wallet Widget form.
      // This flow should eventually call 'walletTokenizationPaymentCreation' when it's valid.
      const remoteSubmissionFn =
        wallet[REMOTE_BUTTON_ACCESS_FUNCTIONALITY.BANK_CREATE_PAYMENT_TRIGGER];
      remoteSubmissionFn();
    }
    // If the user is not on ACHForm, trigger walletTokenizationPaymentCreation directly.
    else {
      dispatch(walletTokenizationPaymentCreation(undefined));
    }
  } else if (isCreditCardPaymentMethod(paymentMethodType)) {
    // Remotely trigger submission lifecycle of the Wallet Widget form.
    // This flow should eventually call 'walletTokenizationPaymentCreation' when it's valid.

    // If it's the CardForm, then the widget form will take over.
    // If it's a card wallet, we require users to input their CVV again - trigger it here!
    const remoteSubmissionFn =
      wallet[REMOTE_BUTTON_ACCESS_FUNCTIONALITY.CARD_CREATE_PAYMENT_TRIGGER];
    remoteSubmissionFn();
    // NOTE: Forms dispatch 'walletTokenizationPaymentCreation' actions on their own. We don't do it here.
  } else if (isNanopayPaymentMethod(paymentMethodType)) {
    dispatch(paymentActions.createPayment());
    dispatch(submitPayment(undefined));
    return;
  } else if (isPayPalPaymentMethod(paymentMethodType)) {
    dispatch(submitPayment(undefined));
  } else {
    dispatch(walletTokenizationPaymentCreation(undefined));
  }
});

export const submitPayment = thunkActionFactory<any>(
  async ({ payload = {}, dispatch, state, businessLogic }) => {
    const { bankDetails, ccDetails } = payload;
    let domainId: string,
      token: string,
      balanceAmount: number,
      invoiceAmount: number,
      transactionType: string,
      currency: string,
      isFullyPaid: string,
      isPartiallyPaid: string,
      invoiceDueDate: string,
      companyLocale: string,
      riskProfileToken: string,
      paymentDetailsMessage: string | undefined,
      isSubscription: boolean,
      isGpu: boolean | null;
    const {
      payment: {
        paymentMethodType,
        paymentMethodSubType,
        isDateScheduled,
        isAutoPayOn,
        gratuityValue = 0,
        amount,
        isSubscriptionUpdatePaymentMethodRequired,
        inputAmount,
      },
      auth: { isSalesCheckoutInvoice, isQBDTInvoiceRollBack, recipientEmail },
      sale,
      insight,
      companyInfo,
      config: { ssrtid },
      wallet: { selectedWalletId, userWallets = [], enabledCCTypes, isNewWalletUI },
      ixp,
      featureFlags = {},
    } = state;

    domainId = insightSelectors.domainId(insight);
    token = insightSelectors.tokenSelector(insight);
    balanceAmount = saleSelectors.balanceSelector(sale);
    invoiceAmount = saleSelectors.amountSelector(sale);
    transactionType = saleSelectors.typeSelector(sale);
    currency = saleSelectors.currencySelector(sale);
    isFullyPaid = insightSelectors.isFullyPaidSelector(insight);
    isPartiallyPaid = insightSelectors.isPartiallyPaidSelector(insight);
    invoiceDueDate = saleSelectors.dueDateSelector(sale);
    companyLocale = companyInfoSelectors.localeSelector(companyInfo);
    riskProfileToken = insightSelectors.riskProfileTokenSelector(insight);
    paymentDetailsMessage = saleSelectors.paymentDetailsMessageSelector(sale);
    isSubscription = saleSelectors.isSubscription(sale);
    const paymentDetailMessage = saleSelectors.paymentDetailsMessageSelector(sale);
    isGpu = saleSelectors.isGpu(sale);
    let submitPaymentMark = mark('submitPayment');
    logIfMissingriskProfileToken({
      riskProfileToken,
      featureFlags,
      ssrtid,
      token,
      where: 'submitPaymentStart',
      Logger: Logger.warn,
    });
    SegmentIO.transactionSubmitted();
    const isOneToMany = saleSelectors.isOneToManyPaymentRequest(sale);
    const isValidMAIPasPDF = isMAIPasPDF({ sale, featureFlags })
      ? businessLogic.payment.isContactDetailsEmailValid
      : true;
    const validIfOneToMany = isOneToMany
      ? businessLogic.payment.isContactDetailsNameValid &&
        businessLogic.payment.isContactDetailsEmailValid
      : true;
    if (!validIfOneToMany || !isValidMAIPasPDF) {
      dispatch(validateContactDetailsName());
      dispatch(validateContactDetailsEmail());
      dispatch(paymentActions.cancelPayment());
      return;
    }

    let paymentBody: {
        isUpdated?: boolean;
        isUpdate?: boolean;
        accountEmail?: string;
        riskProfileToken?: string;
        paidInvoices?: Array<{
          invoiceNumber?: number;
          balance: number;
        }>;
        tokenNumber: string;
      },
      applePayInfo: ApplePayInfo,
      nanopayDetails: {
        nanopayToken: string;
        nanopaySourceAccount: string;
        maskedBankAccountNumber: string;
      };
    if (
      (isDateScheduled || isAutoPayOn) &&
      !isSubscription &&
      (isBankAccountPaymentMethod(paymentMethodType) ||
        isCreditCardPaymentMethod(paymentMethodType))
    ) {
      if (
        isCreditCardPaymentMethod(paymentMethodType) &&
        selectedWalletId &&
        !isAmexAccountEnabled(selectedWalletId, userWallets, enabledCCTypes)
      ) {
        dispatch(
          onFailedPayment(
            {
              response: {
                data: {
                  message: 'PAYFLOW_VALIDATION_CARD_TYPE_INVALID',
                  cardType: 'american-express',
                },
              },
            },
            featureFlags
          )
        );
        return;
      }
      paymentBody = schedulePaymentHelpers.getSchedulePaymentPayload({
        state,
        bankDetails,
        ccDetails,
      });
    } else if (isSubscription) {
      paymentBody = subscriptionPaymentHelpers.getSubscriptionPaymentPayload({
        state,
        bankDetails,
        ccDetails,
      });
      if (isSubscriptionUpdatePaymentMethodRequired) {
        SegmentIO.transactionEngaged({
          ui_object: 'button',
          ui_object_detail: 'update_payment_method',
          ui_action: 'clicked',
          ui_access_point: 'transaction_flow',
        });
        paymentBody.isUpdate = true;
      } else {
        SegmentIO.transactionEngaged({
          ui_object: 'button',
          ui_object_detail: 'subscribe',
          ui_action: 'clicked',
          ui_access_point: 'transaction_flow',
        });
      }
      if (ccDetails) {
        paymentBody = {
          ...getCCPaymentPayloadForCpServer(ccDetails, state),
          ...paymentBody,
        };
      }
    } else if (isBankAccountPaymentMethod(paymentMethodType)) {
      paymentBody = getBankPaymentPayloadForCpServer(bankDetails, state);
    } else if (isCreditCardPaymentMethod(paymentMethodType)) {
      if (
        selectedWalletId &&
        !isAmexAccountEnabled(selectedWalletId, userWallets, enabledCCTypes)
      ) {
        dispatch(
          onFailedPayment(
            {
              response: {
                data: {
                  message: 'PAYFLOW_VALIDATION_CARD_TYPE_INVALID',
                  cardType: 'american-express',
                },
              },
            },
            featureFlags
          )
        );
        return;
      }
      try {
        paymentBody = getCCPaymentPayloadForCpServer(ccDetails, state);
      } catch (e) {
        dispatch(
          onFailedPayment(
            {
              response: {
                data: { message: 'CC_PAYMENT_PAYLOAD_INVALID', errorMessage: (e as Error).message },
              },
            },
            featureFlags
          )
        );
        return;
      }
    } else if (isNanopayPaymentMethod(paymentMethodType)) {
      const {
        nanopay: { hasNanopayConnected, userBearerToken, payingWithAccount },
      } = state as { nanopay: Nanopay };

      if (!hasNanopayConnected || !userBearerToken || !payingWithAccount || !payingWithAccount.id) {
        dispatch(
          onFailedPayment(
            { response: { data: { message: 'NANOPAY_PAYMENT_PAYLOAD_INVALID' } } },
            featureFlags
          )
        );
        return;
      }
      try {
        nanopayDetails = {
          nanopaySourceAccount: payingWithAccount.id,
          nanopayToken: userBearerToken,
          maskedBankAccountNumber: formatMaskedAccountNumber(payingWithAccount.accountNumberMask),
        };
        paymentBody = getNanopayBankPaymentPayloadForCpServer(nanopayDetails, state);
      } catch (e) {
        dispatch(
          onFailedPayment(
            {
              response: {
                data: {
                  message: 'NANOPAY_PAYMENT_PAYLOAD_INVALID',
                  errorMessage: (e as Error).message,
                },
              },
            },
            featureFlags
          )
        );
        return;
      }
    } else if (isApplePayPaymentMethod(paymentMethodType)) {
      let makeApplePayPaymentMark = mark('makeApplePayPayment');
      try {
        const applePayPaymentResult = await makeApplePayPayment(state, businessLogic);
        if (applePayPaymentResult.status === 'CANCELED') {
          dispatch(paymentActions.cancelPayment());
          return;
        }
        applePayInfo = { ...applePayPaymentResult.payment, isGpu };
        paymentBody = getApplePayPaymentPayloadForCpServer(
          applePayPaymentResult.payment.token,
          state
        );
      } catch (e) {
        Logger.error({
          EventName: 'CPV2ApplePayError',
          ErrorMessage: (e as Error).message,
          StackTrace: (e as Error).stack,
        });
        dispatch(paymentActions.cancelPayment());
        dispatch(onFailedPayment(e as Error, featureFlags));
        return;
      }
      makeApplePayPaymentMark.finish();
    } else if (isPayPalPaymentMethod(paymentMethodType)) {
      reportPrePayment({}, state);
      dispatch(makePayPalPayment());
      return;
    } else {
      // TODO: External Payment Method
      throw Error('We do not support external payment methods yet');
    }

    // cp-server pass field named riskProfileToken to PAY API
    paymentBody.accountEmail = recipientEmail;
    const additionalHeaders: RawAxiosRequestHeaders = {
      Accept: 'application/json, text/javascript, */*; q=0.01',
      'aws-wallet': true,
      token,
    };
    // This field is only for supporting payment flow for QBDT rollback
    // Will need to remove once the transition is complete
    if (isQBDTInvoiceRollBack) {
      additionalHeaders['qbdt-scs-rollback'] = true;
    }
    const headers: RawAxiosRequestHeaders = getHeadersForSSRRequest(state, additionalHeaders);

    const gratuity = eligibleGratuityAmount(state);
    let result, paymentType: string;

    try {
      await lockForriskProfileToken(() => state)
        .then(() => {
          riskProfileToken = insightSelectors.riskProfileTokenSelector(insight);
          paymentBody.riskProfileToken = riskProfileToken;
        })
        .catch(() => {
          mark('failed to get riskProfileToken in time for payment');
          logIfMissingriskProfileToken({
            ssrtid,
            token,
            riskProfileToken,
            where: 'afterLockForriskProfileToken',
            featureFlags,
            Logger: Logger.warn,
          });
        });
      await reportPrePayment(paymentBody, state);

      if (isDateScheduled || (isAutoPayOn && !isSubscription)) {
        let schedulePaymentMark = mark('schedulePayment');
        paymentType = 'schedulePayment';
        result = await businessLogic.payment.schedulePayment({
          domainId,
          token,
          data: paymentBody,
        });

        if (result.status === 200) {
          dispatch(paymentActions.createSchedulePaymentSuccess({ result }));
        } else {
          dispatch(paymentActions.createPaymentError(result));
        }
        schedulePaymentMark.finish();
      } else if (isSubscription) {
        result = await businessLogic.payment.subscriptionPayment({
          domainId,
          token,
          data: paymentBody,
        });
        if (result && result.data.paymentStatus === 'SUCCESS' && result.data.subscriptionInfo) {
          dispatch(updateSubscriptionInfo(result.data.subscriptionInfo));
        }
      } else {
        const scsEnabled = scsPaymentEnabled({
          paymentMethodType: paymentMethodType,
          featureFlags,
        });

        const shouldPayThroughScs = scsEnabled || isOneToMany || businessLogic.sale.requiresDeposit;
        if (isSalesCheckoutInvoice && shouldPayThroughScs) {
          const displayGratuityFeatureBool = displayGratuityFeature(state);
          let submitSCSPaymentMark = mark('submitSCSPayment');
          paymentType = 'scsPayment';
          result = await businessLogic.payment.submitSCSPayment({
            ccDetails,
            bankDetails,
            nanopayDetails,
            wallet: state.wallet,
            amountPaid: amount,
            paymentMethodType,
            timeout: 60000,
            applePayInfo,
            ...(displayGratuityFeatureBool &&
              gratuityValue && {
                gratuityAmount: gratuityValue,
                displayGratuityFeature: displayGratuityFeatureBool,
              }),
            featureFlags,
          });
          submitSCSPaymentMark.finish();
        } else {
          let submitNonSCSPayment = mark('submit non SCS Payment');
          paymentType = 'icnPayment';
          result = await httpClient({
            url: '/icnportal-server/rest/payments',
            method: 'POST',
            headers,
            data: paymentBody,
            endpoint: '/icnportal-server/rest/payments',
            timeout: 60000,
            ssrtid,
            token,
            companyLocale,
          });
          submitNonSCSPayment.finish();
        }
      }
      const isSuccessfulPayment =
        result.data.paymentStatus === 'SUCCESS' ||
        result.data.paymentStatus === 'PENDING' ||
        result.data.paymentStatus === 'CAPTURED';

      if (isSuccessfulPayment) {
        // store the paid invoices since it is not part of the payment response currently
        const { paidInvoices } = paymentBody;
        paidInvoices && dispatch(paymentActions.storePaidInvoices({ paidInvoices }));
        // if the payment method was saved re-fetch the wallet
        if (result.data.savePaymentMethodSuccessful) {
          dispatch(fetchWallets());
        }

        let usedPaymentMethod;

        if (bankDetails) {
          usedPaymentMethod = bankDetails;
        } else if (ccDetails) {
          usedPaymentMethod = ccDetails;
        } else if (isApplePayPaymentMethod(paymentMethodType)) {
          // paymentBody.tokenNumber - is a JSON in string (as sent to CP server)
          const {
            token: {
              paymentMethod: { displayName, network },
            },
          } = JSON.parse(paymentBody.tokenNumber);
          usedPaymentMethod = { accountNumber: displayName, cardType: network };
        } else {
          // find the wallet item used for paying
          if (isBankAccountPaymentMethod(paymentMethodType)) {
            usedPaymentMethod = userWallets.find((bank) => bank.id === selectedWalletId);
          } else if (isCreditCardPaymentMethod(paymentMethodType)) {
            usedPaymentMethod = userWallets.find((card) => card.id === selectedWalletId);
          } else if (isNanopayPaymentMethod(paymentMethodType)) {
            const {
              nanopay: { payingWithAccount },
            } = state as { nanopay: Nanopay };
            usedPaymentMethod = {
              accountNumber:
                payingWithAccount && payingWithAccount.accountNumberMask
                  ? payingWithAccount.accountNumberMask
                  : '',
            };
          }
        }

        dispatch(onSuccessfulPayment({ result, usedPaymentMethod }));
      } else {
        if (result?.data?.errorCode === PAYMENT_ERROR_TYPES.PAYMENT_ERROR_V2) {
          dispatch(
            modalActions.show({
              background: 'inherit',
              component: ActionModal as unknown as () => JSX.Element,
              componentProps: {
                withCancelBtn: false,
                onContinue: () => window.location.reload(),
                hide: () => window.location.reload(),
                headerIntl: {
                  id: 'MAIP_PAINTED_DOOR_ERROR_HEADER',
                  defaultMessage: 'Online payment isn’t available for this invoice ',
                },
                bodyIntl: {
                  id: 'MAIP_PAINTED_DOOR_ERROR_BODY',
                  defaultMessage:
                    'Follow the merchant’s payment instructions to pay this invoice. If you need help, contact the merchant directly.',
                },
                continueIntl: {
                  id: 'SETTINGS_CLOSE',
                  defaultMessage: 'Close',
                },
                paymentDetailMessage,
                hideOnContinueFinish: false,
                marginTop: '36px',
              },
            })
          );
          return dispatch(paymentActions.cancelPayment());
        }
        dispatch(onFailedPayment(result, featureFlags, dispatch));
      }
      const { data = {} } = result;
      const {
        paymentMethod,
        amountPaid,
        bankAccountWalletPayment,
        creditCardWalletPayment,
        savePaymentMethodSuccessful,
        trackingId,
      } = data;
      const intuit_tid =
        result.config && result.config.headers && result.config.headers['intuit_tid'];

      businessLogic.insight.reportInvoicePayInCP({
        companyInfo,
        insight,
        sale,
        data,
        intuit_tid,
        ssrtid,
        paymentMethodType,
        paymentType,
        gratuityValue,
        isDateScheduled,
        isAutoPayOn,
        isNewWalletUI,
        ixp,
        inputAmount,
        paymentMethodSubType,
      });

      mark('logged Invoice_pay_in_cp');
      const scheduleActive = isDateScheduled || isAutoPayOn;
      SegmentIO.paymentResult({
        status: isSuccessfulPayment,
        paymentMethod,
        paymentMethodType,
        gratuity,
        amountPaid,
        bankAccountWalletPayment,
        creditCardWalletPayment,
        savePaymentMethodSuccessful,
        trackingId,
        balanceAmount,
        invoiceAmount,
        transactionType,
        currency,
        isFullyPaid,
        isPartiallyPaid,
        invoiceDueDate,
        paidInvoices: paymentBody.paidInvoices,
        paymentDetailsMessage,
        ixp,
        scheduleActive,
        // @ts-ignore
        paymentType,
        token,
        isGpu,
      });

      logPerformanceMarks({ token, ssrtid, riskProfileToken, intuit_tid });
    } catch (e) {
      const scheduleActive = isDateScheduled || isAutoPayOn;
      const headers: RawAxiosRequestHeaders = (e as ErrorWithResponse)?.config?.headers || {};
      const { intuit_tid } = headers;
      SegmentIO.paymentResult({
        status: 'error',
        paymentMethodType,
        transactionType,
        gratuity,
        paymentDetailsMessage,
        ixp,
        scheduleActive,
        isGpu,
      });
      reportPaymentNewSyntax({
        currency,
        gratuityValue,
        intuit_tid,
        isAutoPayOn,
        isDateScheduled,
        isNewWalletUI,
        paymentType,
        amountPaid: amount,
        paymentMethod: paymentMethodType,
        paymentMethodSubType,
        paymentStatus: 'error',
        error: { stack: (e as Error)?.stack, message: (e as Error)?.message },
      });
      SegmentIO.transactionFailed();

      dispatch(onFailedPayment(e as Error, featureFlags));
      logPerformanceMarks({
        token,
        ssrtid,
        riskProfileToken,
        intuit_tid: (e as ErrorWithResponse).config
          ? (e as ErrorWithResponse).config?.headers['intuit_tid']
          : null,
      });
    }
    submitPaymentMark.finish();
  }
);

export const walletTokenizationPaymentCreation = thunkActionFactory<any>(
  async ({ payload = {}, dispatch, state }) => {
    // For PCI compliance we need to tokenize financial information
    // Tokenization means sending the raw information to the wallet service
    // (they are PCI compliant) and they will give us a token that represents
    // this payment method (or wallet).

    const { bankDetails, ccDetails } = payload as {
      bankDetails: UserWalletBank;
      ccDetails: UserWalletCard;
    };
    let invoiceToken: string;
    const { config, payment, insight, wallet } = state;

    const { paymentMethodType } = payment;
    if (payment && payment.isPayPalCommerceInvoice) {
      return dispatch(paymentActions.createPayment());
    }

    invoiceToken = insightSelectors.tokenSelector(insight);

    if (payment.paymentStatus === TXN_MAP.STATUS.IN_PROGRESS) {
      Logger.debug('Payment is in progress, we need to reject this.');
      return dispatch(paymentActions.rejectPaymentRequest());
    }

    const isCardCreation = (['cc', 'dc', 'dc,cc'] as Array<Payment['paymentMethodType']>).includes(
      paymentMethodType
    );
    const isNotExternalPayment = !(['pp'] as Array<Payment['paymentMethodType']>).includes(
      paymentMethodType
    );

    if (isCardCreation) {
      const { selectedWalletId } = wallet;
      if (selectedWalletId === null || selectedWalletId === 'AddCard') {
        Logger.debug('CardForm! We will tokenize it here');

        try {
          return await dispatch(walletCardTokenization(ccDetails));
        } catch (e) {
          Logger.error({
            EventName: 'Tokenize Wallet Error',
            ErrorMessage: (e as Error) && (e as Error).message,
            EventType: 'walletTokenization',
            ssrtid: config.ssrtid,
            token: invoiceToken,
          });
          return dispatch(paymentActions.tokenizeWalletError({ error: e }));
        }
      }
    }
    dispatch(paymentActions.createPayment());

    if (isNotExternalPayment) {
      return dispatch(walletBankTokenization(bankDetails));
    }
  }
);

export const onFailedPayment = (error: ErrorWithResponse, featureFlags: FeatureFlags) => {
  mark('failed payment');
  SegmentIO.transactionFailed();
  return {
    type: paymentActions.createPaymentError.type,
    payload: {
      error,
      featureFlags,
    },
  };
};

const walletCardTokenization = thunkActionFactory<any>(
  async ({ payload, dispatch, state, businessLogic }) => {
    const ccDetails = payload;
    const { config, wallet, featureFlags, insight, payment } = state;
    const invoiceToken = insightSelectors.tokenSelector(insight);

    if (wallet.enabledCCTypes.indexOf('amex') === -1 && ccDetails.cardType === 'american-express') {
      dispatch(
        onFailedPayment(
          {
            response: {
              data: { message: 'PAYFLOW_VALIDATION_CARD_TYPE_INVALID', ...ccDetails },
            },
          },
          featureFlags
        )
      );
    }

    const postalCode = getZipCode(ccDetails);
    if (!postalCode) {
      Logger.info({
        EventName: 'missingZipCode',
        EventType: 'walletTokenization',
        ssrtid: config.ssrtid,
        token: invoiceToken,
      });
    }
    ccDetails.address = {
      postalCode,
      country: payment.country.a2,
    };
    dispatch(paymentActions.tokenizeWallet());

    let token: string, backUpToken: string;

    try {
      ({ token, backUpToken } = await businessLogic.wallet.createShortLivedToken(state, {
        ccDetails,
      }));
    } catch (e) {
      return walletTokenizationErrorHandler(dispatch, featureFlags, e as Error);
    }

    // Add this to the ccDetails
    ccDetails.token = token;
    ccDetails.backUpToken = backUpToken;

    return dispatch(submitPayment({ ccDetails }));
  }
);

const walletBankTokenization = thunkActionFactory<any>(
  async ({ payload, dispatch, state, businessLogic }) => {
    const bankDetails = payload;
    const {
      payment: { paymentMethodType },
    } = state;
    if (bankDetails) {
      try {
        const result = await businessLogic.wallet.createShortLivedToken(state, {
          bankDetails,
          paymentMethodType,
        });

        if (result && result.token) {
          bankDetails.token = result.token;
        }
        // eslint-disable-next-line no-empty
      } catch (e) {} // Not failing the payment for bank short lived token since its not yet used for payment requests, only creating durable
    }
    return dispatch(submitPayment({ bankDetails }));
  }
);

const walletTokenizationErrorHandler = (
  dispatch: Dispatch<any>,
  featureFlags: FeatureFlags,
  e = {}
) => {
  Logger.error({
    EventName: 'wallet_tokenization',
    ErrorMessage: (e as Error).message,
    StackTrace: (e as Error).stack,
  });
  return dispatch(onFailedPayment(e, featureFlags));
};

export const validateContactDetailsName = thunkActionFactory(({ dispatch, businessLogic }) => {
  dispatch(
    paymentActions.validateContactDetailsName(businessLogic.payment.isContactDetailsNameValid)
  );
});

export const validateContactDetailsEmail = thunkActionFactory(({ dispatch, businessLogic }) => {
  dispatch(
    paymentActions.validateContactDetailsEmail(businessLogic.payment.isContactDetailsEmailValid)
  );
});

export const makeApplePayPayment = async (state: RootState) => {
  let companyName: string, companyLocale: string, currency: string;
  const {
    sale,
    config: { applePay, portal },
    payment: { amount },
    wallet: { enabledCCTypes },
    companyInfo,
  } = state;

  currency = saleSelectors.currencySelector(sale);
  companyLocale = companyInfoSelectors.localeSelector(companyInfo);
  companyName = companyInfoSelectors.nameSelector(companyInfo);

  const headers: RawAxiosRequestHeaders = getHeadersForSSRRequest(state);
  const countryCode = convertLocaleToCountry(companyLocale);
  const foundApplePayCountry = applePay.countryConfig.find(
    (countrySettings: { country: string }) => countrySettings.country === countryCode
  );
  let applePaySupportedCardsInLocale: Array<string> | undefined;
  try {
    applePaySupportedCardsInLocale = foundApplePayCountry && foundApplePayCountry.supportedNetworks;
  } catch (e) {
    Logger.error({
      EventName: 'CPV2ApplePayError',
      ErrorMessage: `foundApplePayCountry.supportedNetworks - ${(e as Error).message}`,
      StackTrace: (e as Error).stack,
    });
    applePaySupportedCardsInLocale = [];
  }

  const paymentRequest = {
    companyName,
    amount,
    currencyCode: currency,
    countryCode,
    supportedNetworks: enabledCCTypes,
  };

  mark('openApplePayPayment');

  return await openApplePayPaymentBL({
    paymentRequest,
    applePaySupportedCardsInLocale,
    portal,
    headers,
  });
};

export const makePayPalPayment = thunkActionFactory(async ({ dispatch, state }) => {
  const { payment, auth, insight, sale, companyInfo, config } = state;

  const domainId = insightSelectors.domainId(insight);
  const token = insightSelectors.tokenSelector(insight);
  const paymentDueStatus = insightSelectors.paymentDueStatusSelector(insight);
  const offerId = insightSelectors.offerIdSelector(insight);
  const balanceAmount = saleSelectors.balanceSelector(sale);
  const invoiceAmount = saleSelectors.amountSelector(sale);
  const transactionType = saleSelectors.typeSelector(sale);
  const currency = saleSelectors.currencySelector(sale);
  const companyLocale = companyInfoSelectors.localeSelector(companyInfo);
  const { isPayPalCommerceInvoice } = payment;

  if (!window.paypal) {
    Logger.error({
      EventName: 'CPV2makePayPalPaymentError',
      ErrorMessage: 'Object window.paypal is undefined',
      isPayPalCommerceInvoice,
    });
    dispatch(paymentActions.cancelPayment());
    return;
  }

  // Open PayPal dialog
  openPayPalSandBox();

  try {
    let getPayPalCheckOutMark = mark('getPayPalCheckOut');
    const payPalCheckOut = await getPayPalCheckOut({
      domainId,
      token,
      balanceAmount,
      paymentDueStatus,
      invoiceAmount,
      transactionType,
      currency,
      companyLocale,
      offerId,
      payment,
      auth,
      config,
    });
    const {
      data: { paypalToken, success },
    } = payPalCheckOut;
    if (success) {
      startPayPalFlow(paypalToken);
    } else {
      closePayPalFlow();
      dispatch(paymentActions.cancelPayment());
    }
    getPayPalCheckOutMark.finish();
  } catch (e) {
    closePayPalFlow();
    dispatch(paymentActions.cancelPayment());

    Logger.error({
      EventName: 'CPV2PayPalError',
      ErrorMessage: (e as Error).message,
      StackTrace: (e as Error).stack,
      isPayPalCommerceInvoice,
    });
    mark('error getPayPalCheckOut');
  }
});

export const onSuccessfulPayment = thunkActionFactory<{
  result: AxiosResponse;
  usedPaymentMethod: string;
}>(({ payload, dispatch }) => {
  const { result, usedPaymentMethod = {} } = payload;
  const { accountNumber, cardNumber, cardType } = usedPaymentMethod as {
    accountNumber: number;
    cardNumber: number;
    cardType: CardType;
  };
  // It doesn't matter if it's ACHForm, CardForm, wallet, or wallet that was just saved, we always assign this field.
  if (accountNumber) {
    result.data.maskedAccountNumber = accountNumber;
  } else if (cardNumber) {
    result.data.maskedCardNumber = cardNumber;
    result.data.cardType = cardType;
  }
  dispatch(
    paymentActions.createPaymentSuccess({
      result,
    })
  );

  const {
    data: { amountPaid },
  } = result;

  dispatch(updateNewBalance({ amountPaid }));
  mark('successful payment');
});

function logPerformanceMarks({
  token,
  ssrtid,
  riskProfileToken,
  intuit_tid,
}: {
  token: string;
  ssrtid: string;
  riskProfileToken: string;
  intuit_tid: string;
}) {
  if (
    window &&
    window.performance &&
    !!window.performance.measure &&
    !!window.performance.getEntriesByType
  ) {
    Logger.info({
      EventType: 'bfs',
      EventName: 'PerformanceReport',
      EventMessage: 'Logging performance marks',
      marks: performance.getEntriesByType('mark'),
      durations: performance.getEntriesByType('measure'),
      timeOrigin: performance.timeOrigin ? performance.timeOrigin : 'not supported',
      performance: performance.toJSON ? performance.toJSON() : 'not supported',
      token,
      ssrtid,
      riskProfileToken,
      intuit_tid,
    });
  }
}

export const setScheduledDate = thunkActionFactory<Date>(({ payload, dispatch, state }) => {
  const scheduledDate = payload;
  const { featureFlags } = state;

  const isDateScheduled =
    new Date(scheduledDate) > new Date() ||
    (featureFlags['should-ignore-due-date-schedule-pay'] && scheduledDate);
  SegmentIO.transactionEngaged({
    activity_type: 'schedule_pay',
    ui_object: 'dropdown',
    ui_object_detail: 'select_schedule_pay_date',
    ui_action: 'clicked',
    ui_access_point: 'transaction_flow',
  });
  dispatch(
    paymentActions.setScheduledDate({
      scheduledDate,
      isDateScheduled,
    })
  );
});

export const hideSuccessScreen = thunkActionFactory(({ dispatch }) => {
  dispatch(setDefaultInputAmount());
  dispatch(paymentAmountReset());
  dispatch(paymentActions.hideSuccessScreen());
});

export const createPaypalOrder = thunkActionFactory<Payment['paymentMethodType']>(
  async ({ payload, dispatch, state, businessLogic }) => {
    const usedPaymentMethod = payload;
    const {
      payment: {
        gratuityValue: gratuityAmount,
        amount: paymentAmount,
        contactDetailsName,
        contactDetailsEmail,
      } = {},
      featureFlags = {},
      sale,
    } = state;
    try {
      const isValidMAIPasPDF = isMAIPasPDF({ sale, featureFlags })
        ? businessLogic.payment.isContactDetailsEmailValid
        : true;
      if (!isValidMAIPasPDF) {
        dispatch(validateContactDetailsEmail());
        dispatch(paymentActions.cancelPayment());
        return;
      }
      reportPrePayment({}, state);
      const response = await businessLogic.payment.handlePaypalOrder({
        action: 'CREATE',
        usedPaymentMethod,
        gratuityAmount,
        paymentAmount,
        intuitOrderId: undefined,
        contactDetailsName,
        contactDetailsEmail,
        featureFlags,
      });
      const { orderId: intuitOrderId, paypalOrderId } = (response && response.data) || {};
      if (!intuitOrderId) {
        const error = { data: { message: 'PAYPAL_CREATE_ORDER' } };
        return dispatch(onFailedPayment(error, featureFlags));
      }
      dispatch(
        paymentActions.setPayPalOrderId({
          intuitOrderId,
        })
      );
      return paypalOrderId;
    } catch (e) {
      const error = { data: { message: 'PAYPAL_CREATE_ORDER' } };
      return dispatch(onFailedPayment(error, featureFlags));
    }
  }
);

export const cancelScheduledPayment = thunkActionFactory(
  async ({ dispatch, state, businessLogic }) => {
    const {
      sale: { scheduleInfo },
      insight: { token, domainId },
    } = state;
    const scheduleTemplateId = scheduleInfo && scheduleInfo.scheduleTemplateId;

    const result = await businessLogic.payment.deleteSchedulePayment({
      token,
      domainId,
      id: scheduleTemplateId,
    });

    if (result.status === 200) {
      dispatch(cancelScheduledPaymentSuccess());
    } else {
      dispatch(cancelScheduledPaymentError(result));
    }
  }
);

export const cancelScheduledPaymentSuccess = thunkActionFactory(async () => {
  location.reload();
});

export const cancelScheduledPaymentError = thunkActionFactory<{ error: Error }>(
  async ({ payload, dispatch }) => {
    dispatch(paymentActions.createPaymentError(payload));
  }
);

export const setContactDetailsName = thunkActionFactory<Payment['contactDetailsName']>(
  ({ payload, dispatch, businessLogic }) => {
    businessLogic.payment.contactDetailsName = payload;
    dispatch(paymentActions.setContactDetailsName(businessLogic.payment.contactDetailsName));
  }
);

export const setContactDetailsEmail = thunkActionFactory<Payment['contactDetailsEmail']>(
  ({ dispatch, payload, businessLogic }) => {
    businessLogic.payment.contactDetailsEmail = payload;
    dispatch(paymentActions.setContactDetailsEmail(businessLogic.payment.contactDetailsEmail));
  }
);

export const checkIFPaypalEnabled = thunkActionFactory(({ dispatch, state, businessLogic }) => {
  try {
    const {
      sale: { type },
      wallet: { enabledPaymentMethods, userWallets } = {},
      payment: { paymentMethodType } = {},
      insight: { view2pay: { IsPayable } = {} },
      companyInfo: { sourceOffering } = {},
      featureFlags = {},
    } = state;
    if (
      IsPayable &&
      Array.isArray(enabledPaymentMethods) &&
      enabledPaymentMethods.includes('paypal_ppaam')
    ) {
      const payload = businessLogic.payment.checkIFPaypalEnabled({
        sourceOffering,
        saleType: type,
        enabledPaymentMethods,
        featureFlags,
        paymentMethodType,
        userWallets,
      }) as { paymentMethodType: Payment['paymentMethodType'] };
      dispatch(paymentActions.shouldShowPaypalAndVenmo(payload));
      dispatch(walletActions.shouldShowPaypalAndVenmo(payload));
    }
  } catch (e) {
    Logger.error({
      EventName: 'store/payment/actions :: checkIFPaypalEnabled',
      ErrorMessage: (e as Error).message,
      StackTrace: (e as Error).stack,
    });
  }
  dispatch(walletActions.detectPayPalEnabledStatusFinished());
});

export const setIsSavePaymentMethodChecked = thunkActionFactory<
  Payment['isSavePaymentMethodChecked']
>(({ payload, dispatch, businessLogic }) => {
  businessLogic.payment.isSavePaymentMethodChecked = payload;
  SegmentIO.transactionEngaged({
    activity_type: 'wallet',
    ui_object: 'checkbox',
    ui_object_detail: 'save_payment_method',
    ui_action: payload ? 'enabled' : 'disabled',
    ui_access_point: 'transaction_flow',
  });
  dispatch(
    paymentActions.setIsSavePaymentMethodChecked(businessLogic.payment.isSavePaymentMethodChecked)
  );
});

export const cancelSubscriptionPaymentError = thunkActionFactory<any>(
  async ({ payload, dispatch }) => {
    dispatch(paymentActions.createPaymentError({ error: payload }));
  }
);

export const cancelSubscriptionPayment = thunkActionFactory(
  async ({ dispatch, state, businessLogic }) => {
    const {
      insight: { token, domainId },
    } = state;

    const result = await businessLogic.payment.cancelSubscriptionPayment({ token, domainId });

    if (result.status === 200) {
      location.reload();
    } else {
      dispatch(cancelSubscriptionPaymentError(result));
    }
  }
);

export const confirmPaypalOrder = thunkActionFactory(
  async ({ payload, dispatch, state, businessLogic }) => {
    const usedPaymentMethod = payload;
    const {
      companyInfo,
      insight,
      sale,
      ixp,
      featureFlags,
      payment = {},
      config: { ssrtid } = {},
    } = state;
    const { paymentMethodSubType } = payment;
    const handleFailedPayment = ({
      data,
      intuit_tid,
      paymentMethod,
    }: {
      data: {
        gratuityAmount: number;
        intuitOrderId: string;
        intuit_tid: string;
        paymentAmount: number;
        paymentMethod: PaymentMethod | undefined;
        paymentStatus: keyof typeof TXN_MAP.STATUS;
      };
      intuit_tid: string;
      paymentMethod: PaymentMethod | undefined;
    }) => {
      dispatch(onFailedPayment({ response: { data: {} } }, featureFlags));
      businessLogic.insight.reportInvoicePayInCP({
        companyInfo,
        insight,
        sale,
        data,
        intuit_tid,
        ssrtid,
        paymentMethod,
        paymentMethodSubType,
      });
    };
    try {
      const {
        gratuityValue: gratuityAmount,
        amount: paymentAmount,
        intuitOrderId,
        contactDetailsName,
        contactDetailsEmail,
      } = payment;

      const result = await businessLogic.payment.handlePaypalOrder({
        action: 'CONFIRM',
        intuitOrderId,
        usedPaymentMethod,
        gratuityAmount,
        paymentAmount,
        contactDetailsName,
        contactDetailsEmail,
        featureFlags,
      });
      const { data = {}, config = {} } = result || {};
      const { paymentStatus, paymentMethod } = data;
      const intuit_tid = config.headers && result.config.headers['intuit_tid'];

      if (
        paymentStatus === 'ORDER_CAPTURED' &&
        ['VENMO_PPAAM', 'PAYPAL_PPAAM'].includes(paymentMethod)
      ) {
        const adaptedPaymentMethod = paymentMethod === 'VENMO_PPAAM' ? 'Venmo' : 'PayPal';
        result.data.paymentMethod = adaptedPaymentMethod;
        dispatch(onSuccessfulPayment({ result }));
        businessLogic.insight.reportInvoicePayInCP({
          companyInfo,
          insight,
          sale,
          data,
          intuit_tid,
          ssrtid,
          paymentMethod,
          ixp,
          paymentMethodSubType,
        });
      } else {
        handleFailedPayment({ data, intuit_tid, paymentMethod });
      }
    } catch (e) {
      const intuit_tid =
        (e as ErrorWithResponse).config && (e as ErrorWithResponse).config?.headers
          ? (e as ErrorWithResponse).config?.headers['intuit_tid']
          : null;
      handleFailedPayment({ data: {}, intuit_tid, paymentMethod: usedPaymentMethod });
    }
  }
);
