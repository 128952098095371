import { PaymentMethod } from '../../types/constants';

import { isMAIPasPDF } from './helpers';

import type { FUNDING_SOURCE } from '@paypal/paypal-js';
import type { RawAxiosRequestHeaders } from 'axios';
import type { Sale } from 'types/Sale';
import type { Wallet } from 'types/Wallet';

import { reportPaymentNewSyntax } from 'businessLogic/Insight/Reporting';
import SegmentIO from 'reporting/SegmentIO';
import HttpClient from 'server/helpers/HttpClient';
import { getInitialSelectedPaymentMethod, getWeakTicket } from 'shared/clientUtils';
import { PayPalOrderIds, invoiceInfo, paypalOrderData } from 'types/BusinessLogic';
import { FeatureFlags } from 'types/FeatureFlags';
import { IXP } from 'types/IXP';
import { Payment } from 'types/Payment';

const { SplunkReporter } = require('reporting/splunk/SplunkReporter');
const splunkReporter = SplunkReporter.getInstance();
const logger = 'businessLogic/Payment/Paypal';
const httpClient = HttpClient.getInstance();
export const handlePaypalOrder = async ({
  action,
  intuitOrderId,
  portal,
  headers,
  authToken,
  amount,
  currencyCode,
  isGpu,
  subType,
  displayName,
  toEmails,
  contactDetailsName,
  contactDetailsEmail,
  profileToken,
  usedPaymentMethod,
  gratuityAmount = 0,
  type,
  contact,
  featureFlags,
}: {
  action: 'CREATE' | 'CONFIRM';
  intuitOrderId?: string;
  portal: string;
  headers: RawAxiosRequestHeaders;
  authToken: string;
  amount: number;
  currencyCode: string;
  isGpu: boolean;
  subType?: Sale['subType'];
  displayName?: Sale['contact']['displayName'];
  toEmails?: Sale['contact']['toEmails'];
  contactDetailsName?: Payment['contactDetailsName'];
  contactDetailsEmail?: Payment['contactDetailsEmail'];
  usedPaymentMethod: string;
  gratuityAmount: number;
  profileToken: string;
  type: Sale['type'];
  ssrtid: string;
  token: string;
  featureFlags: FeatureFlags;
  contact: Sale['contact'];
}) => {
  const method = convertFundingSourceToPaymentMethod(usedPaymentMethod);

  try {
    if (amount < 1) {
      throw new Error('cannot pay amount < 1');
    }
    const endpoint = action === 'CREATE' ? 'create-order' : `confirm-order/${intuitOrderId}`;
    let payorName = displayName,
      payorEmail = toEmails;
    if (subType === 'PAYMENT_LINK_MULTIPLE' && contactDetailsName && contactDetailsEmail) {
      payorName = contactDetailsName;
      payorEmail = [contactDetailsEmail];
    } else if (isMAIPasPDF({ sale: { type, contact }, featureFlags }) && contactDetailsEmail) {
      payorEmail = [contactDetailsEmail];
    }
    const data: paypalOrderData = {
      payment: {
        method,
        amount: amount.toFixed(2),
        currencyCode,
      },
      risk: {
        profileToken,
      },
      sale: {
        type,
        isGpu: isGpu,
        subType,
      },
      contact: {
        name: payorName,
        emails: payorEmail,
      },
    };
    if (gratuityAmount > 0) {
      data.payment.gratuityAmount = gratuityAmount;
    }
    //@ts-ignore
    headers['Authorization'] = `Bearer ${authToken}`;
    const handlePaypalOrderResponse = await httpClient({
      url: `/${portal}/rest/paypal/ppaam/${endpoint}`,
      endpoint: `/${portal}/rest/paypal/ppaam/${
        action === 'CREATE' ? 'create-order' : 'confirm-order/{intuitOrderId}'
      }`,
      method: 'POST',
      data,
      headers,
      timeout: 60000,
    });
    return handlePaypalOrderResponse;
  } catch (e: any) {
    const { message = '' } = e;
    return { status: 'ERROR', message };
  }
};

export const checkIFPaypalEnabled = ({
  sourceOffering,
  saleType,
  enabledPaymentMethods: originalEnabledPaymentMethods = [],
  featureFlags = {},
  paymentMethodType: currentSelectedPaymentMethod,
}: {
  sourceOffering: string;
  saleType: Sale['type'];
  userWallets: Wallet['userWallets'];
  enabledPaymentMethods: Array<string>;
  shouldShowSchedulePay: boolean;
  shouldShowAutopay: boolean;
  featureFlags: Record<string, boolean>;
  paymentMethodType: string | null;
  ixp: IXP;
}) => {
  try {
    const isPaymentRequest = saleType === 'PAYMENT_REQUEST';
    const isQBOInvoice = saleType === 'INVOICE' && ['QBO', 'QBMONEY'].includes(sourceOffering);
    const isSaleTypeSupported = isPaymentRequest || isQBOInvoice;
    const __isEligibleAccordingToPaypal = (fundingSource: FUNDING_SOURCE) => {
      if (typeof window === 'undefined' || !window.paypal) {
        throw new Error(`window.paypal is empty, typeof window = ${typeof window}`);
      }
      // @ts-ignore TODO CPV2-5741 throw error if (!window.paypal)
      const paypalFundingSource = window.paypal.Buttons({ fundingSource });
      const isFundingSourceEligible =
        paypalFundingSource.isEligible && paypalFundingSource.isEligible();
      paypalFundingSource &&
        paypalFundingSource.close &&
        paypalFundingSource.close().catch((e: any) => {
          const knownErrors = [
            'Detected container element removed from DOM',
            'Component closed',
            'Close is not a function',
            'L.onClose is not a function',
            `L.onClose is not a function. (In 'L.onClose()', 'L.onClose' is undefined)`,
          ];
          if (!knownErrors.includes(e.message)) {
            splunkReporter.contextual({
              logInfo: { logLevel: 'error', logger },
              event: 'viewSale',
              action: 'detectPayPalEligibility',
              activityInfo: {
                status: 'error',
                initialEnabledPaymentMethods: originalEnabledPaymentMethods,
                enabeldPaymentMethods: originalEnabledPaymentMethods,
              },
              error: {
                stack: e.stack,
                message: e.message,
              },
            });
          }
        });
      return isFundingSourceEligible;
    };
    const __checkIsEligibleForSale = (fundingSource: FUNDING_SOURCE) =>
      isSaleTypeSupported && __isEligibleAccordingToPaypal(fundingSource);
    let updatedPaymentMethodType = currentSelectedPaymentMethod;

    // In case we are disabling paypal then we need to also change the selected payment method
    const __updateSelectedPaymentMethod = (paymentMethod: string) => {
      if (paymentMethod !== currentSelectedPaymentMethod) {
        return currentSelectedPaymentMethod;
      }
      // @ts-ignore
      updatedPaymentMethodType = getInitialSelectedPaymentMethod({
        enabledPaymentOptions: enabledPaymentMethods,
      });
      return updatedPaymentMethodType;
    };

    let enabledPaymentMethods = originalEnabledPaymentMethods;

    const checkEligibilityByPaymentMethodMap: {
      fundingSource: FUNDING_SOURCE;
      paymentMethod: PaymentMethod;
      featureFlag: string;
    }[] = [
      { fundingSource: 'venmo', paymentMethod: 'venmo', featureFlag: 'block-venmo-ppaam-payments' },
      {
        fundingSource: 'paypal',
        paymentMethod: 'paypal_ppaam',
        featureFlag: 'block-paypal-ppaam-payments',
      },
      {
        fundingSource: 'paylater',
        paymentMethod: 'paypal_ppaam paylater',
        featureFlag: 'block-paypal-ppaam-paylater-payments',
      },
    ];

    checkEligibilityByPaymentMethodMap.forEach(({ fundingSource, paymentMethod, featureFlag }) => {
      if (!__checkIsEligibleForSale(fundingSource) || featureFlags[featureFlag]) {
        enabledPaymentMethods = enabledPaymentMethods.filter((item) => item !== paymentMethod);
        __updateSelectedPaymentMethod(paymentMethod);
      }
    });

    splunkReporter.contextual({
      logInfo: { logLevel: 'info', logger },
      event: 'viewSale',
      action: 'detectPayPalEligibility',
      activityInfo: {
        status: 'success',
        initialEnabledPaymentMethods: originalEnabledPaymentMethods,
        enabeldPaymentMethods: enabledPaymentMethods,
        isPayPalEnabled: enabledPaymentMethods.includes('paypal_ppaam'),
        isVenmoEnabled: enabledPaymentMethods.includes('venmo'),
      },
    });
    return {
      enabledPaymentMethods,
      paymentMethodType: updatedPaymentMethodType,
    };
  } catch (e: any) {
    splunkReporter.contextual({
      logInfo: { logLevel: 'error', logger },
      event: 'viewSale',
      action: 'detectPayPalEligibility',
      activityInfo: {
        status: 'error',
        initial_enabled_payment_methods: originalEnabledPaymentMethods,
        enabeld_payment_methods: originalEnabledPaymentMethods,
      },
      error: {
        stack: e.stack,
        message: e.message,
      },
    });

    return {
      enabledPaymentMethods: originalEnabledPaymentMethods,
      paymentMethodType: currentSelectedPaymentMethod,
    };
  }
};

export const finalizeOrderOnPayPalCommerce = async (
  data: PayPalOrderIds,
  paymentsValue: any,
  featureFlags: Record<string, any> = {}
) => {
  const {
    domainId,
    token,
    isPartiallyPaid,
    balanceAmount,
    invoiceAmount,
    transactionType,
    invoiceDueDate,
    companyLocale,
    offerId,
    currency,
    paymentDetailsMessage,
    customerName,
    auth: {
      entityId,
      realmId,
      ticket,
      isUserSignedIn,
      isEntityPromoted,
      syncToken,
      authLevel,
      isSalesCheckoutInvoice,
      authToken,
      recipientEmail,
    },
    config: { ssrtid, portal },
    payment: {
      amount,
      paymentMethodType,
      isPayPalCommerceInvoice,
      ppCaptureOrderKey,
      payPalProcessor,
    },
  } = paymentsValue;
  const { orderID, payerID, orderId } = data;
  const currencyCode = currency;
  let orderFromPPC;
  let shouldRerouteToScs = shouldReroutePayPalCommerceToSCS(isSalesCheckoutInvoice, featureFlags);
  if (orderID) {
    orderFromPPC = orderID;
  } else {
    orderFromPPC = orderId;
  }
  if (data.notAllowedPayPalCardPayment) {
    const error = new Error('3DS failed for the payer, stopping payment');
    let invoiceInfo: invoiceInfo = {
      ssrtid,
      companyLocale,
      token,
      ppCaptureOrderKey,
    };
    Object.assign(error, invoiceInfo);
    throw error;
  }
  let processor = 'APPCONNECT';
  try {
    const requestHeaders: RawAxiosRequestHeaders = {
      currencyCode,
      offerId: offerId,
      payerId: payerID,
      paypalProcessor: processor,
      amount: amount,
      paypalToken: orderFromPPC,
      prevBalance: balanceAmount,
      invoiceAmount: invoiceAmount,
      Accept: 'application/json, text/javascript, */*; q=0.01',
      'Content-Type': 'application/json',
      sessionticket: ticket,
      'intuit-authlevel': authLevel,
      'Intuit-RealmId': realmId,
      'Intuit-DomainId': domainId,
      'Intuit-IntuitId': entityId,
      'Intuit-ACSToken': token,
      token: token,
      isPayPalCommerceInvoice: isPayPalCommerceInvoice,
      isClientStateSignedIn: isUserSignedIn,
      isEntityPromoted: isEntityPromoted,
      ppCaptureOrderKey: ppCaptureOrderKey,
    };

    if (isSalesCheckoutInvoice) {
      requestHeaders['Authorization'] = `Bearer ${authToken}`;
    }
    if (syncToken) {
      requestHeaders['syncToken'] = syncToken;
    }
    const payorEmail = recipientEmail;
    let capturePaypalUrl,
      WeakTicketScsHeaders,
      paypalPaymentContext = {};
    if (shouldRerouteToScs) {
      capturePaypalUrl = `/${portal}/app/CommerceNetwork/view/rest/external-payment/capture-payment/paypal`;
      WeakTicketScsHeaders = getWeakTicket({
        domainId,
        entityId,
        realmId,
        token,
        ticket,
        isUserSignedIn,
        syncToken,
        authToken,
        ssrtid,
      });
      paypalPaymentContext = {
        isNewPaypalFlow: isPayPalCommerceInvoice,
        paypalOrderId: orderFromPPC,
        payerId: payerID,
        ppCaptureOrderKey,
        paypalToken: orderFromPPC,
      };
    } else {
      capturePaypalUrl = `/icnportal-server/rest/payments/paypal/completePay`;
    }
    const capturePayPalPayment = await httpClient({
      url: capturePaypalUrl,
      method: 'POST',
      headers: shouldRerouteToScs ? WeakTicketScsHeaders : requestHeaders,
      endpoint: capturePaypalUrl,
      data: {
        accountEmail: payorEmail,
        transactionType,
        companyLocale,
        amount,
        currencyCode,
        customerName,
        paypalPaymentContext,
        sub_flow: shouldRerouteToScs ? 'PayPal_Commerce_SCS' : 'PayPal_Commerce_ICN',
      },
      ssrtid,
      timeout: 30000,
      token,
      event: 'pay',
    });
    const {
      data: {
        errorMsg,
        success,
        paymentStatus,
        trackingId,
        currencySymbol: currency,
        invoiceBalance,
        invoiceNumber,
      },
    } = capturePayPalPayment;
    if (success || paymentStatus === 'SUCCESS') {
      let result = {
        data: {
          paymentMethod: 'PayPalCommerce',
          paymentStatus: 'SUCCESS',
          trackingId,
          // the date returned from capturePay API has invalid pattern
          paymentDate: Date.now(),
          amountPaid: amount,
          savePaymentMethodSuccessful: false,
          successful: success,
          creditCardWalletPayment: false,
          bankPayment: false,
          cardPayment: false,
          payPalPayment: true,
          bankAccountWalletPayment: false,
        },
      };

      //@ts-ignore
      reportPaymentNewSyntax({
        amountPaid: amount,
        currency,
        intuit_tid: entityId,
        paymentMethod: 'PayPal',
        paymentStatus,
        paymentType: shouldRerouteToScs ? 'scsPayment' : 'icnPayment',
        trackingId,
        payPalProcessor,
        isPayPalCommerceInvoice,
      });

      SegmentIO.paymentResult({
        status: success,
        paymentMethod: 'PayPal',
        paymentMethodType,
        amountPaid: amount,
        trackingId: orderFromPPC,
        balanceAmount: invoiceBalance,
        invoiceAmount,
        currency,
        payPalProcessor,
        isFullyPaid: amount >= invoiceAmount,
        isPartiallyPaid,
        invoiceDueDate,
        paidInvoices: [invoiceNumber],
        paymentDetailsMessage,
      });
      return result;
    } else {
      const error = new Error(errorMsg);
      let invoiceInfo: invoiceInfo = {
        ssrtid,
        companyLocale,
        token,
        ppCaptureOrderKey,
      };
      Object.assign(error, invoiceInfo);
      throw error;
    }
  } catch (error: any) {
    SegmentIO.paymentResult({
      status: 'error',
      paymentMethodType,
      paymentDetailsMessage,
    });
    error.invoiceInfo = {
      ssrtid,
      companyLocale,
      token,
      ppCaptureOrderKey,
    };
    throw error;
  }
};
export const shouldReroutePayPalCommerceToSCS = (
  isSalesCheckoutInvoice: boolean,
  featureFlags: Record<string, any>
) => {
  return !!(
    isSalesCheckoutInvoice &&
    featureFlags &&
    featureFlags['scs-paypal-appconnect-commerce-enabled']
  );
};

export const shouldShowSchedulePayDisabledMessage = ({
  enabledPaymentMethods,
  isUserSignedIn,
  paymentMethodType,
}: {
  enabledPaymentMethods: [string];
  isUserSignedIn: boolean;
  paymentMethodType: string;
}) => {
  try {
    const isPPAAMOnlySale =
      enabledPaymentMethods.filter(
        (paymentMethod) => paymentMethod !== 'paypal_ppaam' && paymentMethod !== 'venmo'
      ).length === 0;
    return (
      isUserSignedIn && ['paypal_ppaam', 'venmo'].includes(paymentMethodType) && !isPPAAMOnlySale
    );
  } catch (e) {
    return false;
  }
};

interface SafariNavigator extends Navigator {
  standalone?: boolean;
}
export const shouldShowVenmoInegratedBrowswerMessage = ({
  paymentMethodType,
}: {
  paymentMethodType: string;
}) => {
  if (typeof window !== 'object') return;
  const perfNavigator: SafariNavigator = window.navigator;
  return !!(paymentMethodType === 'venmo' && perfNavigator?.standalone);
};

export const isPayPalSetOnTheWindow = (paymentMethodType: string) => {
  if (typeof window === 'undefined' || !window.paypal) {
    splunkReporter.contextual({
      logInfo: { logLevel: 'error', logger },
      event: 'viewSale',
      action: 'detectPayPalEligibility',
      activityInfo: {
        status: 'error',
        paymentMethodType,
      },
      error: {
        message: `window.paypal is empty, typeof window = ${typeof window}`,
      },
    });
    return false;
  }
  return true;
};

const convertFundingSourceToPaymentMethod = (fundingSource: string) => {
  const fundingSourceConverted = typeof fundingSource === 'string' && fundingSource.toLowerCase();
  return ['paypal', 'paylater'].includes(fundingSourceConverted as string)
    ? 'PAYPAL_PPAAM'
    : 'VENMO_PPAAM';
};
