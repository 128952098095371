import Link from 'next/link';
import React from 'react';
import { useDispatch } from 'react-redux';

import FatQuestionMark from 'components/Shared/Icons/FatQuestionMark';
import SegmentIO from 'reporting/SegmentIO';
import { modalActions } from 'store/modal/slice';
import { colors, fontSize } from 'styles/cp';
interface BankTransferCAHeaderProps {
  title: React.ReactNode;
}

const BankTransferCAHeader = (props: BankTransferCAHeaderProps) => {
  const { title } = props;
  const dispatch = useDispatch();
  const faqLink = 'https://community.intuit.com/articles/1579068-quickbooks-einvoicing-faq';

  const handleFAQLink = () => {
    SegmentIO.trackCanadaBankPaymentsFlow({ flowName: 'faq', flowStatus: 'solo', action: 'click' });
  };

  const handleHideModel = () => {
    SegmentIO.trackCanadaBankPaymentsFlow({ flowName: 'flinks', flowStatus: 'cancelled' });
    dispatch(modalActions.hide());
  };

  return (
    <div className="ca-bank-header-container">
      <div className="title">{title}</div>

      <div className="header-right">
        <Link href={faqLink}>
          <a
            onClick={handleFAQLink}
            className="faq"
            rel="noopener noreferrer"
            href={faqLink}
            target="_blank"
          >
            <FatQuestionMark />
          </a>
        </Link>

        <div className="close-button" onClick={handleHideModel}>
          X
        </div>
      </div>

      <style jsx>{`
        .ca-bank-header-container {
          display: flex;
          justify-content: space-between;
          padding: 15px;

          .title {
            height: 28px;

            font-size: 20px;
            line-height: 28px;
            color: #393a3d;
            margin: 0px 0px;
          }

          .header-right {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 70px;

            .faq {
              cursor: pointer;
              height: 20px;
              margin-left: 10px;
              :global(circle) {
                fill: ${colors.gray02};
              }
              :global(path) {
                stroke: ${colors.gray02};
              }

              &:hover,
              &:focus {
                outline: none;
                :global(circle) {
                  fill: ${colors.gray01};
                }
                :global(path) {
                  stroke: ${colors.gray01};
                }
              }
            }

            .close-button {
              font-size: ${fontSize.ms};
              color: ${colors.gray02};
              cursor: pointer;

              &:hover,
              &:focus {
                color: ${colors.gray01};
              }
            }
          }
        }
      `}</style>
    </div>
  );
};

export default BankTransferCAHeader;
