export enum SurveyOpen {
  None = 'NONE',
  RepeatBusiness = 'REPEAT_BUSINESS',
  Referral = 'REFERRAL',
  Qualtrics = 'QUALTRICS',
}

export interface PostInvoiceSurvey {
  surveyOpen: SurveyOpen;
}
