import { PayPalButtonsComponent } from '@paypal/paypal-js';

import {
  groupLineItems,
  flattenLineItemAdapter,
  calculateFreightAmount,
  calculateDiscountAmount,
} from 'businessLogic/server/scsToIcnAdapter.js';
import HttpClient from 'server/helpers/HttpClient';
import { formatMaskedAccountNumber, formatMaskedCardNumber } from 'shared/PaymentHelpers';
import { getWeakTicket } from 'shared/clientUtils';
import { TXN_MAP, SALE_MAP } from 'types/constants';
import { ValueOf } from 'types/utils';

const httpClient = HttpClient.getInstance();
const { SplunkReporter } = require('reporting/splunk/SplunkReporter');
const splunkReporter = SplunkReporter.getInstance();
const logger = 'businessLogic/Payment/Common';
type BaseReqParams = {
  bioCatchSessionId: string;
  paymentMethod: string;
  maskedPayment: string;
  amountPaid: number;
  riskProfileToken: string;
  paymentProcessor: string;
  saleId: number;
  freight: number;
  discount: {
    amount?: number;
  };
  tax: {
    totalTaxAmount?: number;
  };
  lines: [];
  saleType: string;
  currency: string;
  customerName: string;
};

type SubmitPaymentParams = {
  data: Object;
  portal: string;
  ssrtid: string;
  domainId: string;
  token: string;
  ticket: string;
  realmId: string;
  isUserSignedIn: boolean;
  authToken: string;
  syncToken: string;
  entityId: string;
  timeout: number;
};

type LineItem = {
  code?: number;
  productCode?: number;
  totalAmount?: number;
  amount?: number;
  unitPrice?: number;
  type: ValueOf<typeof SALE_MAP.LINE_ITEM_TYPE>;
};
interface paymentSuccessParams {
  paymentMethodType: string;
  successfulPaymentCounter: number;
  paymentSessionCounter: number;
  payload: {
    result: {
      config: {
        headers: {
          intuit_tid: string;
        };
      };
      data: {
        maskedAccountNumber: string;
        maskedCardNumber: string;
        cardType: string;
      };
    };
  };
}
interface FlattenedLineItem {
  description: string;
  amount?: number;
  totalAmount?: number;
  discountAmount?: number;
  productCode?: string;
  code?: string;
  unitPrice?: number;
  quantity: number;
  unitOfMeasure: string;
}

type SCSLineItems = Array<LineItem>;

const getBaseRequestBody = ({
  paymentMethod,
  maskedPayment,
  amountPaid,
  riskProfileToken,
  paymentProcessor,
  saleId,
  freight,
  tax,
  lines,
  saleType,
  currency,
  customerName,
  bioCatchSessionId,
}: BaseReqParams) => {
  return {
    paymentBasicSaleInfo: {
      saleId,
      saleType,
      amountPaid: amountPaid.toFixed(2),
      paymentMethod,
      paymentProcessor,
      customerName,
      currency,
      riskProfileToken,
      maskedPayment,
      bioCatchSessionId,
    },
    saleInfo: {
      id: saleId,
      freightAmount: calculateFreightAmount(freight),
      discountAmount: calculateDiscountAmount(lines),
      taxAmount: tax || 0,
      lines: convertLineItemsToSCSPaymentFormat(groupLineItems(lines)),
    },
  };
};

const submitPayment = async ({
  data,
  portal,
  ssrtid,
  domainId,
  token,
  ticket,
  realmId,
  isUserSignedIn,
  authToken,
  syncToken,
  entityId,
  timeout,
}: SubmitPaymentParams) => {
  const headers = getWeakTicket({
    domainId,
    entityId,
    realmId,
    token,
    ticket,
    isUserSignedIn,
    syncToken,
    authToken,
    ssrtid,
  });
  const endpoint =
    //@ts-ignore
    data?.paymentBasicSaleInfo?.feeAmount === 0 ? `/${portal}/rest/pay/v2` : `/${portal}/rest/pay`;
  const result = await httpClient({
    method: 'POST',
    headers,
    data,
    timeout,
    url: endpoint,
    endpoint,
    event: 'pay',
  });
  return result;
};

const generatePaymentSucessPayload = ({
  payload,
  paymentMethodType,
  successfulPaymentCounter,
  paymentSessionCounter,
}: paymentSuccessParams) => {
  const { data, config: { headers: { intuit_tid = '' } = {} } = {} } =
    (payload && payload.result) || {};

  if (paymentMethodType !== 'ap') {
    if (data && data.maskedAccountNumber) {
      // Transform the bankAccount to the proper format.
      data.maskedAccountNumber = formatMaskedAccountNumber(data.maskedAccountNumber);
    } else if (data && data.maskedCardNumber) {
      // If it's credit card, then format it and put it into data so the Success Screen can show it.
      data.maskedAccountNumber = formatMaskedCardNumber(data.cardType, data.maskedCardNumber);
    }
  }
  return {
    paymentStatus: TXN_MAP.STATUS.SUCCESS,
    paymentError: null,
    paymentSessionCounter: paymentSessionCounter + 1,
    successfulPaymentCounter: successfulPaymentCounter + 1,
    lastSuccessfulPaymentResponse: { ...data, intuit_tid },
  };
};
const isPaypalAppconnectDeclinedOrBlocked = (
  featureFlags: Record<string, any>,
  paymentStatus: string | null,
  payPalProcessor: string
) => {
  return !!(
    featureFlags &&
    featureFlags['block-second-payments-paypal-appconnect'] &&
    (paymentStatus === TXN_MAP.STATUS.ERROR || paymentStatus === TXN_MAP.STATUS.DECLINED) &&
    payPalProcessor === 'APPCONNECT'
  );
};
const closeAllPayPalButtons = (paymentMethodType: string, newPaymentMethodType: string) => {
  const instances: PayPalButtonsComponent[] =
    (typeof window !== 'undefined' &&
      window.paypal &&
      window.paypal.Buttons &&
      // @ts-ignore private API?
      window.paypal.Buttons.instances) ||
    [];
  if (
    instances &&
    ['paypal_ppaam', 'paypal', 'venmo', 'pp'].includes(paymentMethodType) &&
    paymentMethodType !== newPaymentMethodType
  ) {
    instances.map((button: PayPalButtonsComponent) => {
      return button.close().catch((e: any) => {
        splunkReporter.contextual({
          logInfo: { logLevel: 'error', logger },
          event: 'pay',
          action: 'closeButtons',
          activityInfo: {
            status: 'error',
          },
          error: {
            message: e.message,
            stack: e.stack,
          },
        });
      });
    });
  }
};

const isCardPaymentMethod = (paymentMethodType: string) => {
  return !!(
    paymentMethodType === 'cc' ||
    paymentMethodType === 'dc' ||
    paymentMethodType === 'dc,cc'
  );
};
const convertLineItemsToSCSPaymentFormat = (lineItems: SCSLineItems, isAddL3Fields?: boolean) => {
  try {
    return lineItems.map((lineItem: LineItem) => {
      const flattenedLineItem = flattenLineItemAdapter(
        lineItem,
        isAddL3Fields
      ) as FlattenedLineItem;
      // next if is a dirty fix since SCS need an indicator for a discount field
      // in estimate - discount field is negative. in invoice it's positive
      if (lineItem.type === SALE_MAP.LINE_ITEM_TYPE.DISCOUNT_LINE_DETAIL) {
        flattenedLineItem.discountAmount = flattenedLineItem.amount;
      }
      flattenedLineItem.code = flattenedLineItem.productCode;
      delete flattenedLineItem.productCode;
      flattenedLineItem.totalAmount = flattenedLineItem.amount;
      delete flattenedLineItem.amount;
      flattenedLineItem.amount = flattenedLineItem.unitPrice;
      delete flattenedLineItem.unitPrice;
      return flattenedLineItem;
    });
  } catch (e: any) {
    splunkReporter.contextual({
      logInfo: { logLevel: 'error', logger },
      event: 'pay',
      action: 'convertLineItems',
      activityInfo: {
        status: 'error',
      },
      error: {
        message: e.message,
        stack: e.stack,
      },
    });
    return lineItems;
  }
};

export {
  generatePaymentSucessPayload,
  getBaseRequestBody,
  submitPayment,
  convertLineItemsToSCSPaymentFormat,
  isPaypalAppconnectDeclinedOrBlocked,
  closeAllPayPalButtons,
  isCardPaymentMethod,
};
